import { Route, Routes } from "react-router-dom";
import { DeviceManagementLayout } from "organisms/layouts/DeviceManagementLayout";
import {
  OVERVIEW,
  ENROLLMENT,
  DEVICE_MANAGEMENT,
  DEVICE_MANAGEMENT_FAQ,
  ENROLL_PHONE,
  ENROLL_TABLET,
  ENROLL_SMART_PHONE,
} from "utils/constants/Routes";
import Enrollment from "pages/deviceManagementPortal/enrollment/Enrollment";
import Overview from "pages/deviceManagementPortal/overview/Overview";
import FAQ from "pages/deviceManagementPortal/faq/FAQ";
import EnrollSmartPhone from "pages/deviceManagementPortal/enrollment/EnrollSmartPhone";
import EnrollTablet from "pages/deviceManagementPortal/enrollment/EnrollTablet";
import EnrollPhone from "pages/deviceManagementPortal/enrollment/EnrollPhone";
import DeviceManagement from "pages/deviceManagementPortal/deviceManagementForm/DeviceManagement";

export const DeviceManagementRoute = () => {
  return (
    <Routes>
      <Route element={<DeviceManagementLayout />}>
        <Route path={DEVICE_MANAGEMENT} element={<DeviceManagement />} />
        <Route path={OVERVIEW} element={<Overview />} />
        <Route path={ENROLLMENT} element={<Enrollment />} />
        <Route path={DEVICE_MANAGEMENT_FAQ} element={<FAQ />} />
        <Route path={ENROLL_SMART_PHONE} element={<EnrollSmartPhone />} />
        <Route path={ENROLL_TABLET} element={<EnrollTablet />} />
        <Route path={ENROLL_PHONE} element={<EnrollPhone />} />
        <Route path="*" element={<DeviceManagement />} />
      </Route>
    </Routes>
  );
};
