import {
  Headings,
  PrimaryButton,
  ModalOverlayWithoutClose,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";

export const ModalOverlayWithOutClose = ({
  title,
  content,
  open,
  callBack,
}: {
  title?: string;
  content?: string;
  open: boolean;
  callBack: () => void;
}) => {
  return (
    <ModalOverlayWithoutClose
      isOpen={open}
      overlayInfo="Modal information"
      className="modal-w-medium"
    >
      <Headings
        className="redhat_medium font-twenty fw-500 mb-2 me-4"
        level={3}
        text={title}
      />
      <p className="mb-4" data-testid="sessionModalDescription">
        {content}
      </p>
      <div className="text-center">
        <PrimaryButton
          data-testid={"modalOkButton"}
          className={"primary_btn btn_block_mob"}
          type="submit"
          onClick={() => callBack()}
        >
          {t("session.label.buttonOk")}
        </PrimaryButton>
      </div>
    </ModalOverlayWithoutClose>
  );
};
