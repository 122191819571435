import { format, parseISO } from "date-fns";

const dateformat = {
  dateFormatMDY: "MM/DD/YYYY",
  dateTimeFormatMDY: "MM/DD/YYYY HH:MM:SS",
  isodateFormatMDY: "MM/dd/yyyy",
  dateFormatISO: "yyyy-MM-dd",
  dateFormatHypen: "MM-dd-yyyy",
  dayMonth: "MM/DD/",
  withAMorPM: "MM/dd/yy hh:mm:ss a",
};

/**
 * Converts a string representing a date to the MM/DD/YYYY format.
 *
 * @param dateString: The input string representing a date.
 * @returns {string} Returns the formatted date string.
 */
export const convertStringToDate = (dateString: string) => {
  try {
    const convertedDate = new Date(dateString);
    return convertedDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  } catch (error) {
    // Handle the error or return a default value as needed
    return "";
  }
};

/**
 * Formats an ISO date string to 'MM/dd/yyyy' format without considering the timezone.
 *
 * @param dateString - The ISO date string to format. It should be in 'yyyy-MM-ddTHH:mm:ssZ' || 'yyyy-MM-dd' || 'yyyy-MM-ddTHH:mm:ss' format.
 *
 * @returns A string representing the date in 'MM/dd/yyyy' format.
 */
export const formatISODateToMDY = (dateString: string) => {
  try {
    // Split the dateString by 'T' to get the date part
    let newDate = dateString.split("T")[0];

    // Parse the date part into a Date object
    const date = parseISO(newDate);

    // Format the Date object with dateformat.dateFormatMDY as the format string
    return format(date, dateformat.isodateFormatMDY);
  } catch (error) {
    // Handle the error or return a default value as needed
    return "";
  }
};

/** Formats as MM/DD/YYYY when the input is YYYY-MM-DD */
export const dobFormat = (date: string) => {
  if (!date) {
    return "";
  }

  const splits = date.split("-");

  if (splits.length === 3) {
    return splits[1] + "/" + splits[2] + "/" + splits[0];
  }

  return "";
};

/** Formats as 'MM/dd/yy hh:mm:ss a' */
export const enrollFormat = (date: string) => {
  if (!date) {
    return "";
  }

  const getFullDateOnly = date.split(" ")[0];
  return format(getFullDateOnly, "MM/dd/yyyy hh:mm:ss a") || "";
};

/** Formats as 'MM/dd/yy' */
export const enrollDateFormat = (date: string) => {
  if (!date) {
    return "";
  }

  const getFullDateOnly = date.split(" ")[0];
  return format(getFullDateOnly, "MM/dd/yyyy") || "";
};

const updateSlash = (date: string) => {
  return date.indexOf("T") ? new Date(date) : new Date(date.replace(/-/g, "/"));
};

/**
 * Converts a Date object to the MM/DD/YYYY format.
 *
 * @param date: The input Date object.
 * @param isMMDDYYY: Optional parameter to show or hide the month in the formatted date.
 * @returns {string} Returns the formatted date string.
 * mm-dd-yyyy
 */
export const convertToDate = (
  date: Date | string,
  isMMDDYYY: boolean = false
) => {
  try {
    const dateObject = typeof date === "string" ? new Date(date) : date;
    return dateObject.toLocaleDateString(isMMDDYYY ? "es-CL" : "en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  } catch (error) {
    // Handle the error or return a default value as needed
    return "";
  }
};

/**
 * Converts a string representing a date to the specified date format (default: MM/DD/YYYY).
 *
 * @param dateString: The input string representing a date.
 * @param isYYMMDD: Optional parameter to show or hide the year in the formatted date.
 * @returns {string} Returns the formatted date string.
 */

export const convertStrDateFormat = (
  dateString: string,
  isYYMMDD: boolean = false
) => {
  try {
    const formattedDate = new Date(dateString).toLocaleDateString(
      isYYMMDD ? "fr-CA" : "en-US",
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
    );

    return formattedDate;
  } catch (error) {
    // Handle the error or return a default value as needed
    return "";
  }
};
