import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Image } from "@sentaraui/optimahealth_web";
import { DEVICE_MANAGEMENT_IMAGE } from "utils/constants/Constants";
import { useBranding, useGlobalState } from "providers";

export const Overview = () => {
    const { setDmTitle } = useGlobalState();
    const { brandConfig } = useBranding();
    useEffect(() => {
        document.title = t('pageTitleADA.deviceManagementOverview');
        setDmTitle(t('deviceManagement.overview.pageHeading'));
    }, [setDmTitle]);

    const SendMePushImage = (
        <Image
            dataTestId={'imgSendMePush'}
            path={DEVICE_MANAGEMENT_IMAGE.image1}
            alttext={t('deviceManagement.overview.SendMePushImg')}
            className="img-fluid"
        />
    )
    const ClickText = t('deviceManagement.overview.content12');
    const EnterPassCodeText = t('deviceManagement.overview.content13');
    const LoginText = t('deviceManagement.overview.content22');
    const TextMeNewCodes = t('deviceManagement.overview.content24');
    return (<div className="d-flex flex-column gap-3 mb-4">
        <div><strong>{t('deviceManagement.overview.content1')}</strong></div>
        <div>{t('deviceManagement.overview.content2')}</div>
        <div><strong>{t('deviceManagement.overview.content3')}</strong></div>
        <div className="redhat_italic">{t('deviceManagement.overview.content4')}</div>
        <ol className="d-flex flex-column gap-3 ps-4">
            <li>
                <div>
                    {t('deviceManagement.overview.content5')}{" "}
                    <strong>{t('deviceManagement.overview.content6')}</strong>.
                </div>
                {SendMePushImage}
            </li>
            <li>
                <div>
                    {t('deviceManagement.overview.content7')}{" "}
                    <strong>{t('deviceManagement.overview.content8')}</strong>{" "}
                    {t('deviceManagement.overview.content9')}.
                </div>
                <Image
                    dataTestId={'imgSentPush'}
                    path={brandConfig.image.DuoSentPushImg}
                    alttext={t('deviceManagement.overview.SentPushImg')}
                    className="img-fluid"
                />
                <div className="redhat_italic pt-3 pb-1">
                    {t('deviceManagement.overview.content10')}
                </div>
                <Image
                    dataTestId={'imgPushExperienceApple'}
                    path={brandConfig.image.DuoPushAppleImg}
                    alttext={t('deviceManagement.overview.PushExperienceAppleImg')}
                    className="img-fluid"
                />
                <Image
                    dataTestId={'imgPushExperienceAndroid'}
                    path={brandConfig.image.DuoPushAndroidImg}
                    alttext={t('deviceManagement.overview.PushExperienceAndroidImg')}
                    className="img-fluid"
                />
            </li>
        </ol>
        <hr className="m-0" />
        <div><strong>{t('deviceManagement.overview.content11')}</strong></div>
        <ol className="d-flex flex-column gap-3 ps-4">
            <li>
                <div>
                    {ClickText}{" "}
                    <strong>{EnterPassCodeText}</strong>
                </div>
                {SendMePushImage}
            </li>
            <li>
                <div>
                    <strong>{t('deviceManagement.overview.content14')}</strong>{" "}
                    {t('deviceManagement.overview.content15')}{" "}
                    <strong>{t('deviceManagement.overview.content16')}</strong>{" "}
                    {t('deviceManagement.overview.content17')}
                </div>
                <div className="pt-3 pb-1">
                    <strong>{t('deviceManagement.overview.content18')}</strong>
                </div>
                <Image
                    dataTestId={'imgKeyIconUserExperienceApple'}
                    path={brandConfig.image.DuoUserExpAppleImg}
                    alttext={t('deviceManagement.overview.KeyIconUserExperienceAppleImg')}
                    className="img-fluid"
                />
                <div className="pt-3 pb-1">
                    <strong>{t('deviceManagement.overview.content19')}</strong>
                </div>
                <Image
                    dataTestId={'imgPushExperienceAndroid'}
                    path={brandConfig.image.DuoUserExpAndroidImg}
                    alttext={t('deviceManagement.overview.KeyIconUserExperienceAndroidImg')}
                    className="img-fluid"
                />
            </li>
            <li>
                <div className="pb-1">
                    <strong>{t('deviceManagement.overview.content20')}</strong>{" "}
                    {t('deviceManagement.overview.content21')}{" "}
                    <strong>{LoginText}.</strong>
                </div>
                <Image
                    dataTestId={'imgEnterPassCode'}
                    path={DEVICE_MANAGEMENT_IMAGE.image7}
                    alttext={t('deviceManagement.overview.EnterPassCodeImg')}
                    className="img-fluid"
                />
            </li>
        </ol>
        <hr className="m-0" />
        <div><strong>{t('deviceManagement.overview.content23')}</strong></div>
        <ol className="d-flex flex-column gap-3 ps-4">
            <li>
                <div>
                    {ClickText}{" "}
                    <strong>{EnterPassCodeText}</strong>
                </div>
                {SendMePushImage}
            </li>
            <li>
                <div>
                    {ClickText}{" "}
                    <strong>{TextMeNewCodes}</strong>{" "}
                    {t('deviceManagement.overview.content25')}
                </div>
                <Image
                    dataTestId={'imgSendMePush'}
                    path={DEVICE_MANAGEMENT_IMAGE.image8}
                    alttext={t('deviceManagement.overview.SendMePushImg')}
                    className="img-fluid"
                />
            </li>
            <li>
                <div className="pb-1">
                    {t('deviceManagement.overview.content26')}{" "}
                    <strong>{t('deviceManagement.overview.content27')}</strong>{" "}
                    {t('deviceManagement.overview.content28')}{" "}
                    <strong>{LoginText}</strong>.
                </div>
                <Image
                    dataTestId={'imgEnterFirstCode'}
                    path={DEVICE_MANAGEMENT_IMAGE.image9}
                    alttext={t('deviceManagement.overview.EnterFirstCodeImg')}
                    className="img-fluid"
                />
            </li>
            <li>
                <div className="pb-1">
                    {t('deviceManagement.overview.content29')}
                </div>
                <ol className="list-alpha d-flex flex-column gap-3">
                    <li>
                        <div className="pb-1">
                            {t('deviceManagement.overview.content30')}
                        </div>
                        <Image
                            dataTestId={'imgEnterFirstCode'}
                            path={DEVICE_MANAGEMENT_IMAGE.image9}
                            alttext={t('deviceManagement.overview.EnterFirstCodeImg')}
                            className="img-fluid"
                        />
                    </li>
                    <li>
                        {t('deviceManagement.overview.content31')}{" "}
                        <strong>{TextMeNewCodes}</strong>{" "}
                        {t('deviceManagement.overview.content32')}{" "}
                    </li>
                    <li>
                        {t('deviceManagement.overview.content33')}{" "}
                        <strong>{t('deviceManagement.overview.content34')}</strong>,{" "}
                        {t('deviceManagement.overview.content35')}
                    </li>
                </ol>
            </li>
        </ol>
        <hr className="m-0" />
        <div><strong>{t('deviceManagement.overview.content36')}</strong></div>
        <ol className="d-flex flex-column gap-3 ps-4">
            <li>
                <div>
                    {ClickText}{" "}
                    <strong>{t('deviceManagement.overview.content37')}</strong>.
                </div>
                {SendMePushImage}
            </li>
            <li>
                <div className="pt-3 pb-1">
                    {t('deviceManagement.overview.content38')}
                </div>
                <Image
                    dataTestId={'imgReceivePhoneCall'}
                    path={DEVICE_MANAGEMENT_IMAGE.image10}
                    alttext={t('deviceManagement.overview.ReceivePhoneCallImg')}
                    className="img-fluid"
                />
            </li>
            <li>
                <div className="pb-1">
                    {t('deviceManagement.overview.content39')}{" "}
                    <strong>{t('deviceManagement.overview.content40')}</strong>{" "}
                    {t('deviceManagement.overview.content41')}
                </div>
                <Image
                    dataTestId={'imgPress1'}
                    path={DEVICE_MANAGEMENT_IMAGE.image11}
                    alttext={t('deviceManagement.overview.Press1Img')}
                    className="img-fluid"
                />
            </li>
        </ol>
    </div>
    )
}
export default Overview;