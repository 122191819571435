import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";
import { useEmployerBenefitsEnrollment } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const EmployerBenefitsEnrollment = () => {
  const { user } = useAuth()
  const { state, getEmployerBenefitsEnrollmentSSO } =
    useEmployerBenefitsEnrollment();

  useEffect(() => {
    document.title = t('pageTitleADA.employerBenefitsEnrollment');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      await getEmployerBenefitsEnrollmentSSO({ userID: getFederationId() });
    }
  };

  const getFederationId = () => {
    let salesforceFederationId ="";
     user?.lobs?.forEach((lob) => {
       if (lob.id===1) {
        salesforceFederationId = lob?.salesforceFederationId;
       } 
     });
     return salesforceFederationId;
   }

  return (
    <>
      <SSONavigationContent
        header={t("sso.header.employerBenefitsEnrollment")}
        ssoUrl={state?.Result?.ssoUrl}
        dataTestId="ssoLinkEmployerBenefitsEnrollment"
        targetName="EmployerBenefitsEnrollment"
      />
    </>
  );
};

export default EmployerBenefitsEnrollment;
