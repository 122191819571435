import { IdRequestType } from "utils/model/CommonTypes";

export const getKey = (_requsted: IdRequestType) => {
  let key = "";
  if (
    _requsted.success.length &&
    !_requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_1";
  } else if (
    !_requsted.success.length &&
    _requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_2";
  } else if (
    !_requsted.success.length &&
    !_requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_3";
  } else if (
    _requsted.success.length &&
    _requsted.pending.length &&
    !_requsted.failed.length
  ) {
    key = "scenario_4";
  } else if (
    _requsted.success.length &&
    !_requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_5";
  } else if (
    !_requsted.success.length &&
    _requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_6";
  } else if (
    _requsted.success.length &&
    _requsted.pending.length &&
    _requsted.failed.length
  ) {
    key = "scenario_7";
  }
  return key;
};
