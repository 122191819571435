import { Headings } from "@sentaraui/optimahealth_web";
import { t } from "i18next";
const ContactUsSuccess = () => {
  return (
    <div>
      <Headings dataTesId="txtContactUsHeading" className="mb-4" level={1} text={t("contactus.title")} />
      <div className="mb-5">
        {t("contactus.titleSuccess")}
      </div>
    </div>
  );
};

export default ContactUsSuccess;
