import { Headings } from "@sentaraui/optimahealth_web";
import { SelectDropdown } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";
import { Form } from "organisms/form/Form";
import ViewEligibilityHeader from "./ViewEligibilityHeader";
import { useEffect, useState } from "react";
import SearchViewEligibility from "./SearchViewEligibility";
import { useAuth, useGlobalState } from "providers";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemberSearch } from "@sentara/sentara-api-hooks-core";
import { LINK, listPerTable } from "utils/constants/Constants";
import { ErrorBy, GlobalApiError } from "organisms/form";
import { VIEW_ELIGIBILITY } from "utils/constants/Routes";
import { BROKERTYPE } from "utils/model/CommonTypes";
import ViewEligibilityForm from "./ViewEligibilityForm";
import { userTypeLob } from "utils/helpers/HelperFn";
import { USERTYPE } from "utils/model/CommonTypes";


const ViewEligibility = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPageLoader } = useGlobalState();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [lobChanged, setLobChanged] = useState<boolean>(false);
  const [criteriaValidation, setCriteriaValidation] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [searchError, setSearchError] = useState({});
  const [hasDirtyFields, setHasDirtyFields] = useState({})
  const navigate = useNavigate();
  const { user } = useAuth();

  const isHidden = sessionStorage.getItem("HideServiceNowMessage");
  const [isShowErrorMessage, setShowErrorMessage] = useState<boolean>(isHidden != "true");

  const userType = userTypeLob(user);
  const [lob, setLob] = useState(userType === USERTYPE.COMMERCIAL || userType === USERTYPE.COMBINED ? searchParams.get("groupId") != null && searchParams.get("groupId")?.length === 0 ? "medicare" : "commercial" : "medicare");

  const {
    getMemberSearch,
    state: memberList,
    status: apiStatus,
    error
  } = useMemberSearch();

  const defaultValues = {
    groupId: searchParams.get("groupId") ?? "",
    policyId: searchParams.get("policyId") ?? "",
    memberId: searchParams.get("memberId") ?? "",
    subscriberSecurityNumber: searchParams.get("subscriberSecurityNumber") ?? "",
    memberBeneficiaryIdentifier: searchParams.get("subscriberMbiNumber") ?? "",
    firstName: searchParams.get("firstName") ?? "",
    lastName: searchParams.get("lastName") ?? "",
    lineOfBusiness: searchParams.get("lineOfBusiness") ?? "commercial",
  };

  useEffect(() => {
    document.title = t("pageTitleADA.viewEligibility");
    if ((lob === "commercial" && searchParams.get("groupId")) || (lob === "medicare" && searchParams.get("groupId") != null)) {
      setClearForm(false)
      const params: any = {};
      searchParams.forEach((item: string, key: string) => {
        if (key === "page") {
          params["skip"] = parseInt(item) - 1;
          params["take"] = listPerTable;
        } else {
          params[key] = item;
        }
      });

      if (params.subscriberSecurityNumber) {
        params.subscriberSecurityNumber =
          params.subscriberSecurityNumber.replaceAll("-", "");
      }

      if(lob === "medicare") {
        params.nationalProducerNumber = user?.npnNumber
      }

      params.lineOfBusiness = lob

      setPageLoader("eligilitySearch", true);
      getMemberSearch(params).then((res) => {
        if (res?.data?.total === 1) {
          navigate(`/app/${VIEW_ELIGIBILITY}/${res.data.Result[0].memberId}/${lob}`, {
            replace: true,
          });
        }
        setIsSubmited(false);
        setPageLoader("eligilitySearch", false);
        setSearchError({})
        if(!res?.error) {
        setLobChanged(false)
        }
      });
    } else {
      setIsSubmited(false);
      setSearchError({})
      setClearForm(false)
      if ((userType === USERTYPE.COMMERCIAL || userType === USERTYPE.COMBINED) && !lobChanged) {
        setLob('commercial')
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (apiStatus === "success") {
      const ele = document.querySelector(
        memberList?.Result.length ? ".table-responsive" : ".alert-global"
      ) as HTMLDivElement;
      ele?.scrollIntoView({ behavior: "smooth" });
    }
  }, [apiStatus]);

  useEffect(() => {
    if(error) {
      setSearchError(error)
      if(Object.keys(hasDirtyFields).length === 0) {
        setSearchError({})
        setClearForm(true)
      }
    }
  }, [error])

  const onSubmit = (formData: any) => {
    const { searchInput, ...rest } = formData;
    const search = { ...rest, page: 1 };
    for (const i in search) {
      if (searchParams.get(i) != search[i]) {
        setIsSubmited(true);
        setSearchParams(search);
        break;
      }
    }
  };

  const lobChange = (e: any) => {
    setLob(e.target.value)
    setSearchError({})
    setCriteriaValidation(false)
    setLobChanged(true)
  }

  const selectCriteriaValidation=(flag:boolean) => {
    setCriteriaValidation(flag)
  }

  return (
    <div aria-live="polite">
      
      <ViewEligibilityHeader />
      {memberList?.Result.length &&
        searchParams.get("groupId") !== null &&
        !isSubmited ? (
        <SearchViewEligibility memberList={memberList} apiStatus={apiStatus} lob={lob} />
      ) : (

        <div>         
          <Headings
            className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
            level={2}
            text={t("viewEligibilityForm.subHeader")}
          />
          {(userType !== USERTYPE.COMBINED) ? (<div className="p-4 border border-radius10 mb-4 light-grey-bg">
            {lob === "commercial" ? t("viewEligibilityForm.subHeaderContent") : t("viewEligibilityForm.subHeaderContentForMedicareLob")}
            <p className="pt-3 mb-0">
              <strong>{t("viewEligibilityForm.subHeaderContent1")}</strong>
            </p>
          </div>) :
            (
              <div className="p-4 border border-radius10 mb-4 light-grey-bg">
                {lob === "commercial" && <div>{t("viewEligibilityForm.subHeaderContentForCombinationLob")}</div>}
                {lob === "commercial" && <div className="pt-3 mb-0">For the best results for Commercial, you <strong>must</strong> select a group from the Group Number list <strong>and</strong> enter the Member ID or Member SSN or complete as much of the first <strong>and</strong> last name as possible. The more information entered in the “Member Last Name” field, the more specific the search will be.</div> }
                {lob === "medicare" && t("viewEligibilityForm.subHeaderContentForMedicareLob")}
                <p className="pt-3 mb-0">
                  <strong>{t("viewEligibilityForm.subHeaderContent1")}</strong>
                </p>
              </div>
            )
          }
          <ErrorBy
            isShow={
              !!(
                !isSubmited &&
                memberList?.Result &&
                !memberList?.Result.length &&
                Array.from(searchParams).length
              )
            }
            message={t("unableToLocate")}
          />
          <ErrorBy isShow={criteriaValidation} message={t("viewEligibilityForm.validation.eitherOneSelected")}/>
          <GlobalApiError apiError={searchError} />
          {(userType === USERTYPE.COMBINED) && <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="mb-4">
                  <label
                    className="pb-1"
                    htmlFor="selectAGroup"
                    data-testid="lblGroupNumber"
                  >
                    Line Of Business
                  </label>
                  <div>
                    <SelectDropdown className="form-select" name="lineOfBusiness" onChange={(e: any) => lobChange(e)}>
                      <option selected key="commercial" value="commercial">Commercial</option>
                      <option key="medicare" value="medicare">Medicare</option>
                    </SelectDropdown>
                  </div>
                </div>
              </div>
          </div>
          }          
          <Form defaultValues={defaultValues} onSubmit={onSubmit}>            
            <ViewEligibilityForm lob={lob} lobChanged = {lobChanged} clearForm = {clearForm} selectCriteriaValidation = {selectCriteriaValidation} setHasDirtyFields={setHasDirtyFields} setSearchError={setSearchError}/>
          </Form>
          <p className="mt-3 mb-5" data-testid="txtSearchResultsNote">
            {t("viewEligibilityForm.matchCriteriaText")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ViewEligibility;
