import { t } from "i18next";
import { FooterContent, Link, Loader } from "@sentaraui/optimahealth_web";
import { Outlet, useLocation, useNavigate } from "react-router";
import { HeaderLogo } from "./";
import ScrollToTop from "./ScrollTop";
import { useAuth, useBranding, useGlobalState } from "providers";
import { useEffect, useRef } from "react";
import { BROKER_DASHBOARD } from "utils/constants/Routes";

export const AuthLayout = () => {
  const { loader } = useGlobalState();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const mainContentRef = useRef<HTMLDivElement>(null);
  const { brandConfig } = useBranding();

  useEffect(() => {
    if (user?.isSORCurrent && !user.isUnAuthorizedUser) {
      if (location.pathname === "/") {
        navigate(
          sessionStorage.getItem("redirectTo") ?? `/app/${BROKER_DASHBOARD}`
        );
      }
    }
    // Set focus on the main content element when the page load
    if (mainContentRef.current) {
      mainContentRef.current.focus();
    }
  }, [user, location]);

  return (
    <>
      {loader && <Loader />}
      <ScrollToTop />
      <div
        className="d-flex flex-column h-100"
        tabIndex={-1}
        ref={mainContentRef}
      >
        {/* Skip to Main Content link */}
        <a href="#main-content" className="skip-link" tabIndex={1}>
          {t("accessibility.message.skipToMainContent")}
        </a>
        {/* Header section */}
        <div>
          <header>
            {/* Container for the header */}
            <div className="container-fluid">
              {/* Navigation bar */}
              <div>
                <nav className="d-flex align-items-center justify-content-between pt-3 mb-3">
                  <div>
                    {/* Component for the header logo */}
                    <HeaderLogo isFromAuth={true} />
                  </div>
                  <div className="dark-gray-color">
                    <Link
                      to={brandConfig.memberBrokerContactUs}
                      className="btn-links link-hover"
                      target="_blank"
                      data-testid="lnkContactUs"
                    >
                      <strong>{t("contactUs")}</strong>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
            {/* Horizontal line separating the header from the rest of the content */}
            <hr className="border-w-2 mb-0" />
          </header>

          {/* Main content container */}
          <main id="main-content" className="container-fluid" role="main">
            <Outlet />
          </main>
          {/* Horizontal line separating the main content from the footer */}
        </div>

        {/* Footer section */}
        <footer className="mt-auto" data-testid="txtFooterContent">
          <FooterContent
            copyrightText={t("footer.label.copyrightText", {
              year: new Date().getFullYear(),
            })}
            footerDescription={t("footer.label.disclaimer")}
          />
        </footer>
      </div>
    </>
  );
};
