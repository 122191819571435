import { t } from "i18next";
import SearchListItem from "./SearchListItem";
import { OrderIDList, SelectedDepType } from "utils/model/CommonTypes";

type Props = {
  pageList?: OrderIDList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
  getIdentificationCard: Function;
  isMedicare:boolean;
};

const SearchTableMobile = ({
  pageList,
  selectedDep,
  page,
  setSelectedDep,
  getIdentificationCard,
  isMedicare,
}: Props) => {
  return (
    <>
      {pageList ?
        <SearchListItem
          {...{
            isMobile: true,
            pageList,
            selectedDep,
            page,
            setSelectedDep,
            getIdentificationCard,
            isMedicare
          }}
        />
        :
        <div className="text-center">{t("noResult")}</div>
      }
    </>
  );
};

export default SearchTableMobile;
