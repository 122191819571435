import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";
import { useMediCareEnrollment } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";


const MedicareAEP = () => {
  const { user } = useAuth();
  const { state, getMediCareEnrollment } = useMediCareEnrollment();
  useEffect(() => {
    document.title = t('pageTitleADA.medicareAEP');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.person?.personID) {
      await getMediCareEnrollment({
        loginID: getFederationId(),
        personID: user?.person?.personID as string,
        effectiveDate: '01/01/2025'
      });
    }
  };

  const getFederationId = () => {
    let salesforceFederationId = "";
    user?.lobs?.forEach((lob) => {
      if (lob.id === 2) {
        salesforceFederationId = lob?.salesforceFederationId;
      }
    });
    return salesforceFederationId;
  }

  return (
    <SSONavigationContent
      header={t("sso.header.medicareAEP")}
      ssoUrl={state?.Result?.ssoUrl}
      dataTestId="ssoLinkMedicareEnrollment"
      targetName="MedicareAEP"
    />
  );
};
export default MedicareAEP;
