import SSONavigationContent from "pages/sso/SSONavigationContent";
import React, { useEffect } from "react";
import { t } from "i18next";
import { useCommissionStatement } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const CommissionStatement = () => {
  const { user } = useAuth();
  const { state, getCommissionStatementSSO } = useCommissionStatement();

  useEffect(() => {
    document.title = t('pageTitleADA.commissionStatement');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      await getCommissionStatementSSO({ userID: user?.loginID });
    }
  };

  return (
    <SSONavigationContent
      header={t("sso.header.commissions")}
      ssoUrl={state?.Result?.ssoUrl}
      dataTestId="ssoLinkCommissionStatement"
      targetName="CommissionStatement"
    />
  );
};
export default CommissionStatement;
