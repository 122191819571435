import { useState, ChangeEvent, useEffect } from "react";
import { Form } from "organisms/form/Form";
import { t } from "i18next";
import { Headings, Link } from "@sentaraui/optimahealth_web";
import ContactUsForm from "./ContactUsForm";
import { LINK } from "utils/constants/Constants";
import { useContactUsSubmit } from "@sentara/sentara-api-hooks-core";
import { useNavigate } from "react-router";
import { CONTACTUS_SUCCESS } from "utils/constants/Routes";
/**
 * Renders the Contact Us page.
 *
 * @returns JSX.Element
 */
import { Brands, useAuth, useBranding } from "providers";
const ContactUsPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { brandConfig } = useBranding();

  const { submitContactUs } = useContactUsSubmit();
  const defaultValues = {
    firstName: user?.person?.firstName ?? "",
    lastName: user?.person?.lastName ?? "",
    email: user?.emailAddresses?.[0]?.emailAddress ?? "",
    isEmailOptIn: "isEmailOptIn",
    IsPhoneOptIn: "",
    isMailOptIn: "",
  };
  const [dropdownValue, setDropdownValue] = useState<string>("");

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDropdownValue(e.target.value);
  };
  const onSubmit = async (formData: any) => {
    const isHealthProductsInformation =
      formData?.isHealthProductsInformation === "isHealthProductsInformation";
    const isBehavioralHealthProductsInformation =
      formData?.isBehavioralHealthProductsInformation ===
      "isBehavioralHealthProductsInformation";
    const isAppointmentInformation =
      formData?.isAppointmentInformation === "isAppointmentInformation";
    const isOtherInformation =
      formData?.isOtherInformation === "isOtherInformation";
    const isEmailOptIn = formData?.isEmailOptIn === "isEmailOptIn";
    const isMailOptIn = formData?.isMailOptIn === "isMailOptIn";
    const IsPhoneOptIn = formData?.IsPhoneOptIn === "IsPhoneOptIn";

    let object = {
      firstName: defaultValues.firstName,
      lastName: defaultValues.lastName,
      ...formData,
      organization: formData.organization,
      phone:
        formData.phone.length > 0
          ? "+1" + formData.phone.replaceAll("-", "")
          : "",
      IsPhoneOptIn: IsPhoneOptIn,
      isMailOptIn: isMailOptIn,
      isEmailOptIn: isEmailOptIn,
      isOtherInformation: isOtherInformation,
      isAppointmentInformation: isAppointmentInformation,
      isBehavioralHealthProductsInformation:
        isBehavioralHealthProductsInformation,
      isHealthProductsInformation: isHealthProductsInformation,
      groupSize: dropdownValue,
      fileAttachment: formData.fileAttachment[0],
    };
    const response = await submitContactUs(object);
    if (response) {
      navigate(`/app/${CONTACTUS_SUCCESS}`);
    }
  };
  useEffect(() => {
    document.title = t('pageTitleADA.contactUs');
  }, [])
  return (
    <div className="text-md-center text-lg-start pb-3">
      <Headings
        className="mb-4"
        level={1}
        text={t("contactus.title")}
        dataTestId="txtContactUsHeading"
      />
      {Brands.AVMED !== brandConfig.brandName && (
        <div className="border-bottom  mb-4">
          <div className="mb-2 font-eighteen fw-600 redhat_semibold">
            {t("contactus.subContent")}{" "}
            <Link
              to={LINK.FREQUENTLY_ASKED_QUESTION}
              target="_blank"
              className="font-eighteen fw-600 redhat_semibold"
            >
              {t("contactus.freqAskedQus")}
            </Link>
            .
          </div>
          <div className="mb-3 pb-1">{t("contactus.fillForm")}</div>
        </div>
      )}
      <Form defaultValues={defaultValues} onSubmit={onSubmit}>
        <ContactUsForm
          handleChange={handleChange}
          dropdownValue={dropdownValue}
        />
      </Form>
    </div>
  );
};

export default ContactUsPage;
