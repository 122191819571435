import { useTranslation } from "react-i18next";
import { COMMISSION_POLICY } from "utils/constants/Constants";

export default function CommissionPolicyTable2() {
  const { t } = useTranslation();
  return (
    <div className="row justify-content-center">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12">
        <div className="text-center mb-3 mt-2">
          <strong>  {t("commissionPolicy.tableHeader2")}</strong>
        </div>
        <table className="table-custom-style w-100" data-testid="tblStandardLargeGroupCommissions">
          <thead>
            <tr>
              <th scope="col">{t("commissionPolicy.eligibleEmployees")}</th>
              <th scope="col">{t("commissionPolicy.rate")}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="font-fourteen">
              <td>{COMMISSION_POLICY.ELIGIBLE_EMPLOYEES1}</td>
              <td>{COMMISSION_POLICY.RATE1}</td>
            </tr>
            <tr className="font-fourteen">
              <td>{COMMISSION_POLICY.ELIGIBLE_EMPLOYEES2}</td>
              <td>{COMMISSION_POLICY.RATE2}</td>
            </tr>
            <tr className="font-fourteen">
              <td>{COMMISSION_POLICY.ELIGIBLE_EMPLOYEES3}</td>
              <td>{COMMISSION_POLICY.RATE3}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
