import { createContext, useContext, useEffect, useMemo, useState  } from "react";
import useLaunchDarkly from "@platform/featuremanagement";
import { useNavigate } from "react-router-dom";
import { UserType } from "utils/model/CommonTypes";
import { AccessMap } from "../../utils/constants/AccessMap";
import { HasR2WAccess } from "@sentara/sentara-api-hooks-core";

interface AuthContextType {
  user?: UserType | null;
  token?: string;
  signOut: (to?: string) => void;
  updateUser: (v: object) => void;
  updateToken: (v: string) => void;
  accessFeatures: string; 
  isAccess: (path: string) => boolean;
}

export const AuthContext = createContext<AuthContextType>({} as any);

interface Props {
  children?: React.ReactNode;
  user?: UserType;
}

export const AuthProvider = ({ children, user: puser }: Props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | null>(puser ?? null);
  const [token, setToken] = useState<string>("");

  /** Start: Launch Darkly */

  const __user = {
    key: "",
    custom: {
      sentaraGroupBroker: false,    
      sentaraMedicareBroker: false, 
      sentaraIndividualBroker: false, 
      avMedMedicareAgent:false,
      avMedIndividualAgent:false,
      avMedEmployerGroupAgent:false,
      avMedMewa: false,
      hasR2WAccess: user?.hasR2WAccess === HasR2WAccess.YES,
      groupReportingDynamicLinkEnabled: user?.groupReportingDynamicLinkEnabled
    },
  };
  
  if (user?.person) {
    let sentaraMedicare = 0;
    let sentaraGroup = 0;
    let sentaraIndividual = 0;
    let avmedMedicare = 0;
    let avmedIndividual = 0;
    let avmedGroup = 0;
    let avMedMewa = 0;
    user.lobs?.forEach((lob) => {
      if (lob.id === 2) {
        sentaraMedicare++;
        avmedMedicare++;
      } 
      if(lob.id === 1) {
        sentaraGroup++;
        avmedGroup++;
      }
      if(lob.id === 3)
      {
        avmedIndividual++;
        sentaraIndividual++;
      }
      if(lob.id  === 4)
      {
        avMedMewa++;
      }
    });
    
    if(!user.isUnAuthorizedUser) {
      __user.custom.sentaraMedicareBroker = user?.carrier?.carrierID === 1 && sentaraMedicare > 0;
      __user.custom.sentaraGroupBroker = user?.carrier?.carrierID === 1 && sentaraGroup > 0;
      __user.custom.sentaraIndividualBroker = user?.carrier?.carrierID === 1 && sentaraIndividual> 0;
      __user.custom.avMedMewa = user?.carrier?.carrierID === 2 && avMedMewa > 0;
      __user.custom.avMedMedicareAgent = user?.carrier?.carrierID === 2 && avmedMedicare > 0;
      __user.custom.avMedIndividualAgent = user?.carrier?.carrierID === 2 && avmedIndividual > 0;
      __user.custom.avMedEmployerGroupAgent = user?.carrier?.carrierID === 2 && avmedGroup > 0;
    }
  }

  const { apiData: ldData } = useLaunchDarkly(
    user?.person ? JSON.stringify(__user) : {},
    process.env.REACT_APP_LAUNCH_DARKLY_KEY,
    1
  );
  const [accessFeatures, setAccessFeatures] = useState<string>("");

  useEffect(() => {
    if (Array.isArray(ldData)) {
      const featureData: { [x: string]: boolean } = {};
      ldData?.forEach((element: { [x: string]: boolean }) => {
        for (const i in element) {
          featureData[i] = element[i];
        }
      });
      setAccessFeatures(JSON.stringify(featureData));
    }
  }, [ldData]);

  const isAccess = (path: string) => {
    const _path = path.split("?")[0].split("/");
    const pathto = _path.slice(0, 3).join("/");
    if (!accessFeatures) {
      return false;
    }
    if (!AccessMap[pathto]) {
      return true;
    }
    const access = JSON.parse(accessFeatures)[AccessMap[pathto]];
    return !!access;
  };
  /** End: Launch Darkly */

  const signOut = (to = "/logout") => {
    updateToken("");
    setUser(null);
    navigate(to);
  };

  const updateUser = (updatedInfo = {}) => {
    const _user = user ?? { loginID: "", isUnAuthorizedUser: false };
    setUser({ ..._user, ...updatedInfo });
  };

  const updateToken = (token: string) => {
    
    if (token) {
      setToken(token);
      
    } else {
      setToken("");
    }
  };

  const value = useMemo(
    () => ({
      user,
      token,
      updateUser,
      updateToken,
      accessFeatures,
      isAccess,
      signOut,
    }),
    [user, token, accessFeatures]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
