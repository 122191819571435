import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { TilesIds } from 'utils/constants/Constants';
import {
    IndividualNewIcon,
    EmployerNewIcon,
    MedicareEnrollmentIcon,
    BookOfBusinessesIcon,
    DocumentNoteIcon,
    SupportIcon,
    VirtualVisitIcon,
    PasswordHelpIcon,
    IdCardIcon
} from "@sentaraui/optimahealth_web";
import { BROKER_BOOK_BUSINESS_REPORTING, DEVICE_MANAGEMENT_PORTAL, EMPLOYER_BENEFITS_ENROLLMENT, INDIVIDUAL_NEW_RENEWING, MEDICARE_ENROLLMENT_2025, VIEW_ELIGIBILITY, ORDER_MEMBER_ID_CARD, MEDICARE_BOOK_BUSINESS, PASSWORD_HELP,SUPPORT } from 'utils/constants/Routes';
import { Brands, useAuth, useBranding } from 'providers';
import { PasswordHelp } from 'utils/constants/Navigation';
interface Tile {
    id: string;
    name: string;
    icon: React.JSX.Element;
    route?: string;
    link?: string;
    isAccessible?: string;
}

export const HomeTiles = () => {
    const { brandConfig } = useBranding();
    const navigate = useNavigate();
    const { user, isAccess } = useAuth();
    const [userType, setUserType] = useState<'commercial' | 'medicare' | 'commercialMedicare' | "">("medicare");
    const tileOrder = {
        'commercialMedicare': [1, 9, 3, 4, 5, 6, 2],
        'medicare': [6, 9, 10, 1,7,8, 2],
        'commercial': [1, 9, 3, 4, 5, 8, 2]
    }
    const tileActions = (tile: Tile) => {
        if (tile.route) {
            navigate(`/app/${tile.route}`);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        if (tile.link) window.open(tile.link, '_blank');
    };

    const tileData: Tile[] = [
        { id: TilesIds.Title1, name: t('nav.tile.viewEligibility'), icon: <DocumentNoteIcon />, route: VIEW_ELIGIBILITY },
        { id: TilesIds.Title2, name: t('nav.tile.passwordHelp'), icon: <PasswordHelpIcon />, link: `${PasswordHelp}`, isAccessible:`${PASSWORD_HELP}` },
        { id: TilesIds.Title3, name: t('nav.tile.bookOfBusiness'), icon: <BookOfBusinessesIcon />, route: BROKER_BOOK_BUSINESS_REPORTING },
        { id: TilesIds.Title4, name: t('nav.tile.individual'), icon: <IndividualNewIcon />, route: INDIVIDUAL_NEW_RENEWING },
        { id: TilesIds.Title5, name: t('nav.tile.employer'), icon: <EmployerNewIcon />, route: EMPLOYER_BENEFITS_ENROLLMENT },
        { id: TilesIds.Title6, name: t('nav.tile.medicare'), icon: <MedicareEnrollmentIcon />, route: MEDICARE_ENROLLMENT_2025 },
        { id: TilesIds.Title7, name: t('nav.tile.deviceManagement'), icon: <VirtualVisitIcon />, link: `/${DEVICE_MANAGEMENT_PORTAL}` },
        { id: TilesIds.Title8, name: t('nav.tile.support'), icon: <SupportIcon />, link: brandConfig.link.supportTileLink,  isAccessible:`${SUPPORT}`},
        { id: TilesIds.Title9, name: t('nav.tile.orderIdCard'), icon: <IdCardIcon />, route: ORDER_MEMBER_ID_CARD },
        { id: TilesIds.Title10, name: t('nav.tile.bookOfBusiness'), icon: <BookOfBusinessesIcon />, route: MEDICARE_BOOK_BUSINESS },
    ];

    useEffect(() => {
        if (user?.lobs) {
            const types = user?.lobs.map((lob) => lob.name);

            let sentaraMedicare = 0;
            let sentaraGroup = 0;
            let sentaraIndividual = 0;
            let avmedMedicare = 0;
            let avmedIndividual = 0;
            let avmedGroup = 0;
            let avMedMewa = 0;

            user.lobs?.forEach((lob) => {
                if (lob.id === 2) {
                    sentaraMedicare++;
                    avmedMedicare++;
                }
                if (lob.id === 1) {
                    sentaraGroup++;
                    avmedGroup++;
                }
                if (lob.id === 3) {
                    avmedIndividual++;
                    sentaraIndividual++;
                }
                if (lob.id === 4) {
                    avMedMewa++;
                }
            });


            let combinationUser = false;
            let medicareUser = false;
            let commericalUser = false;

            if (brandConfig.brandName === Brands.DEFAULT) {
                combinationUser = sentaraMedicare > 0 && (sentaraGroup > 0 || sentaraIndividual > 0)
                medicareUser = sentaraMedicare > 0;
                commericalUser = sentaraGroup > 0 || sentaraIndividual > 0
            }

            if (brandConfig.brandName === Brands.AVMED) {
                combinationUser = avmedMedicare > 0 && (avmedGroup > 0 || avmedIndividual > 0)
                medicareUser = avmedMedicare > 0;
                commericalUser = avmedGroup > 0 || avmedIndividual > 0;
            }

            if (combinationUser && types.length > 1) {
                setUserType('commercialMedicare');
            } else if (medicareUser) {
                setUserType('medicare');
            } else if (commericalUser) {
                setUserType('commercial');
            }
        }
    }, [user]);

    const getTiles = (): Tile[] => {
        let filteredTiles: Tile[] = [];
        if (userType) {
            tileOrder[userType].forEach(element => {
                const filteredTile = tileData[tileData.findIndex(tile => tile.id === `tile${element}`)]
                if (filteredTile?.route && isAccess(`/app/${filteredTile?.route}`)) {
                    filteredTiles.push(filteredTile)
                }
                if (element === 7 && filteredTile?.link  && isAccess(filteredTile?.link)) {
                    filteredTiles.push(filteredTile)
                }
                if (element === 8 && filteredTile?.isAccessible  && isAccess(filteredTile?.isAccessible)) {
                    filteredTiles.push(filteredTile)
                }
                if(element === 2 && filteredTile?.isAccessible && isAccess(filteredTile?.isAccessible)) {
                    filteredTiles.push(filteredTile)
                }
            });
        }
        if (filteredTiles.length >= 7) {
            return filteredTiles.slice(0, 6);
        }
        return filteredTiles;
    };

    return (
        <>
            {userType && getTiles().map((tile) => (
                <button
                    onClick={() => tileActions(tile)}
                    data-testid={`${tile.id}`}
                    className="grid-col d-flex flex-column justify-content-center align-items-center bg-transparent home-tile-link"
                    key={tile.id}
                >
                    <span>{tile.icon}</span>
                    {tile.name}
                </button>
            ))}
        </>
    );
};

export default HomeTiles;