import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../../organisms/layouts";
import { Login } from "../../pages/auth";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_UPDATE,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_CALLBACK,
  LOGOUT,
} from "utils/constants/Routes";
import SignOut from "pages/signOut/SignOut";

export const AuthRoute = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={LOGIN} element={<Login />} />
        <Route path={LOGIN_CALLBACK} element={<Login />} />
        <Route path={LOGOUT} element={<SignOut />} />
        <Route path="*" element={<Login />} />
      </Route>
      <Route path={LOGOUT} element={<SignOut />} />
    </Routes>
  );
};
