import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";
import { useIndividualNewRenew } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const IndividualNewRenewing = () => {
  const { user } = useAuth();
  const { state, getIndividualNewRenew } = useIndividualNewRenew();

  useEffect(() => {
    document.title = t('pageTitleADA.individualNewRenewing');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      await getIndividualNewRenew({ loginID: user?.loginID });
    }
  };

  return (
    <>
      <SSONavigationContent
        header={t("sso.header.individualNewRenewing")}
        ssoUrl={state?.Result?.ssoUrl}
        dataTestId="ssoLinkIndividualNewAndRenewing"
        targetName="IndividualNewRenewing"
      />
    </>
  );
};
export default IndividualNewRenewing;
