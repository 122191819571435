import { FooterLinks, FooterContent } from "@sentaraui/optimahealth_web";
import { Brands, useBranding } from "providers";
import { useTranslation } from "react-i18next";
import { FooterNavType } from "utils/model/CommonTypes";

export const AppFooter = () => {
  const { t } = useTranslation();
  const { brandConfig } = useBranding();

  const navigationLinks = Object.keys(brandConfig.footerNavigation).map(
    (key: string) => {
      const links = brandConfig.footerNavigation;
      const item: FooterNavType = links[key];
      if(key === 'footerContactUs' && brandConfig.brandName === Brands.AVMED) {
        return { ...item, label: t(item.label), url: brandConfig.memberBrokerContactUs }
      }
      return { ...item, label: t(item.label) };
    }
  );

  return (
    <footer className="mt-auto not-print">
      <FooterLinks links={navigationLinks} />
      <FooterContent
        copyrightText={t("footer.label.copyrightText", {
          year: new Date().getFullYear(),
        })}
        footerDescription={t("footer.label.disclaimer")}
      />
    </footer>
  );
};
