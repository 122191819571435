import { SelectDropdown, InputField } from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorBy, FormError, GlobalError } from "../../organisms/form";

import {
  NameRegex,
  NumericAsterisks,
  SSNRegex,
  MBIRegex,
  SubscriberIDRegex,
} from "utils/constants/Regex";
import OrderMemberIDCardSubmit from "./OrderMemberIDCardSubmit";
import { NameChange, ssnOncheck } from "utils/helpers/HelperFn";
import { useAuth } from "providers";
import {
  memberNumberLength, MBILength,
  VALIDATION_LENGTH,
} from "utils/constants/Constants";
import { useNavigate } from "react-router";
import { ORDER_MEMBER_ID_CARD } from "utils/constants/Routes";
import { USERTYPE } from "utils/model/CommonTypes";
import { ChangeEvent, useState } from "react";

interface orderMemberIDCardProps {
  setUserLob: Function;
  userLob: string;
  userType: string;
  setHasDirtyFields: Function;
  setCriteriaValidation: Function;
  criteriaValidation:boolean;
  setSearchError: Function;
}

const OrderMemberIDCardForm = ({ userLob, setUserLob, userType,setHasDirtyFields,criteriaValidation,setCriteriaValidation,setSearchError }: orderMemberIDCardProps) => {

  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const { register, reset, trigger, formState, getValues, resetField } =
    useFormContext();
  //For reseting the form
  const handleClearForm = () => {
    reset({
      groupId: "",
      memberId: "",
      subscriberSecurityNumber: "",
      firstName: "",
      lastName: "",
      checkboxAllMembers: "",
      memberBeneficiaryIdentifier:"",
    });


  setSearchError({})
  setCriteriaValidation(false)
    navigate(`/app/${ORDER_MEMBER_ID_CARD}`);
  };
  const validateFirstName = () => {
    if (getValues("firstName") && getValues("lastName") === "") {
      return t("orderMemberIDCard.validationLastname");
    }
  };

  register("lastName", { validate: validateFirstName });
  const searachByValdate = () => {

    setCriteriaValidation(false);
    if (
      !getValues("memberId") &&
      !getValues("subscriberSecurityNumber") &&
      !getValues("memberBeneficiaryIdentifier")&&
      !getValues("lastName")
    ) {

      setCriteriaValidation(true);
      return t("orderMemberIDCard.atleastOneCriteria");
    }
  };

  register("searchInput", { validate: searachByValdate });
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUserLob(e.target.value);

    setCriteriaValidation(false);

    setSearchError({})


    reset({
      groupId: "",
      memberId: "",
      subscriberSecurityNumber: "",
      memberBeneficiaryIdentifier:"",
      firstName: "",
      lastName: "",
      checkboxAllMembers: "",
  
  });
  };


  setHasDirtyFields(formState.dirtyFields)

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          {/* <GlobalError name="searchInput" /> */}
          <ErrorBy isShow={criteriaValidation} message={t("viewEligibilityForm.validation.eitherOneSelected")}/>

          {userType === USERTYPE.COMBINED && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_line_of_business"
              data-testid="lblOrderMemberIdCard"
            >
              {t("orderMemberIDCard.lineOfBusiness")}
            </label>
            <div>
              <SelectDropdown
                {...register("lineOfBusiness")}
                className={"form-select"}
                dataTestId="optOrderMemberIdCardGroupList"
                id="order_id_line_of_business"
                aria-describedby="order_id_line_of_business_error"
                onChange={handleChange}
              >
                {[t("orderMemberIDCard.commercial"), t("orderMemberIDCard.medicare")].map((item) => {
                  return (
                    <option key={`${item}`} value={item}>
                      {item}
                    </option>
                  );
                })}
              </SelectDropdown>
            </div>
          </div>}
          {(userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED) && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_group_id"
              data-testid="lblOrderMemberIdCard"
            >
              {t("orderMemberIDCard.selectGroup")}
            </label>
            <div>
              <SelectDropdown
                {...register("groupId", {
                  required: t("viewEligibilityForm.selectOption"),
                })}
                className={
                  formState.errors.groupId?.message
                    ? "form-select error-input-bdr"
                    : "form-select"
                }
                dataTestId="optOrderMemberIdCardGroupList"
                id="order_id_group_id"
                aria-describedby="order_id_group_id_error"
              >
                <option value="">{t("orderMemberIDCard.allgroups")}</option>
                {user?.groups?.map((item) => {
                  return (
                    <option key={`${item.groupID}`} value={item.groupID}>
                      {item.groupID} {item.groupName}
                    </option>
                  );
                })}
              </SelectDropdown>
              <div
                className={formState.errors.groupId?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_group_id_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="groupId" />
                </div>
              </div>
            </div>
          </div>}

          {userLob === USERTYPE.MEDICARE && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_member_id"
              data-testid="lblOrderMemberIdCardmemberID"
            >
              {t("orderMemberIDCard.memberID")}
            </label>
            <div>
              <InputField
                maxLength={VALIDATION_LENGTH.MEMBER_ID_MAX_LENGTH}
                pattern={NumericAsterisks}
                {...register("memberId", {
                  minLength: {
                    value: memberNumberLength,
                    message: t("viewEligibilityForm.memberNumberLength"),
                  },
                  pattern: {
                    value: SubscriberIDRegex,
                    message: t("viewEligibilityForm.validateMemberID"),
                  },

                  onChange: (e) => {
                    trigger("searchInput");
                    reset({
                      subscriberSecurityNumber: "",
                      firstName: "",
                      lastName: "",
                      memberBeneficiaryIdentifier:""
                    });
                  },
                })}
                className={
                  formState.errors.memberId?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("orderMemberIDCard.memberPlaceholder")}
                dataTestId="inputOrderMemberIdCardMemberId"
                id="member_id"
                aria-describedby="member_id_error"
              />
              <div
                className={formState.errors.memberId?.message ? "" : "d-none"}
              >
                <div id="member_id_error" aria-live="polite" aria-atomic="true">
                  <FormError name="memberId" />
                </div>
              </div>
              <p className="granite-gray-color pt-1">
                <small>{t("viewEligibilityForm.includeAsterisk")}</small>
              </p>
            </div>

          </div>}


          {userLob === USERTYPE.MEDICARE && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_medicare_beneficiary_identifier"
              data-testid="lblOrderMemberIdCardmedicareBeneficiaryIdentifier"
            >
              {t("orderMemberIDCard.medicareBeneficiaryIdentifier")}
            </label>

            <div>
              <InputField
                maxLength={VALIDATION_LENGTH.MBI_MAX_LENGTH}
                {...register("memberBeneficiaryIdentifier", {
                    minLength: {
                        value: MBILength,
                        message: t("viewEligibilityForm.MBINumberLength"),
                    },

                    onChange: (e) => {
                        trigger("searchInput");
                        resetField("firstName");
                        resetField("lastName");
                        resetField("memberId");
                    },
                    pattern: {
                        value: MBIRegex,
                        message: t("viewEligibilityForm.validationMBI"),
                    },
                })}                  
                className={
                    formState.errors.memberBeneficiaryIdentifier?.message
                        ? "form-input error-input-bdr"
                        : "form-input"
                }
                placeholder={t("viewEligibilityForm.plcMBINumber")}
                dataTestId="inputMBI"
                id="mbi_number"
                aria-describedby="mbi_number_error"
              />
              <div
                className={
                  formState.errors.memberBeneficiaryIdentifier?.message
                    ? ""
                    : "d-none"
                }
              >
                <div id="mbi_number_error" aria-live="polite" aria-atomic="true">
                  <FormError name="memberBeneficiaryIdentifier" />
                </div>
              </div>
            </div>
          </div>}

          {(userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED) && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_member_id"
              data-testid="lblOrderMemberIdCardSubscriberId"
            >
              {t("orderMemberIDCard.subscriberID")}
            </label>
            <div>
              <InputField
                maxLength={20}
                pattern={NumericAsterisks}
                {...register("memberId", {
                  onChange: (e) => {
                    trigger("searchInput");
                    reset({
                      subscriberSecurityNumber: "",
                       firstName: "",
                       lastName: "",
                    });
                  },
                  pattern: {
                    value: SubscriberIDRegex,
                    message: t("orderMemberIDCard.validationSubscriberID"),
                  },
                })}
                className={
                  formState.errors.memberId?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("orderMemberIDCard.SubscriberPlc")}
                dataTestId="inputOrderMemberIdCardSubscriberId"
                id="order_id_member_id"
                aria-describedby="order_id_member_id_error"
              />
              <div
                className={formState.errors.memberId?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_security_no_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="memberId" />
                </div>
              </div>
            </div>
          </div>}

          {(userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED) && <div className="pos-rel pt-2">
            <hr className="m-0" />
            <strong className="or-separator">
              {t("orderMemberIDCard.or")}
            </strong>
          </div>}

          {(userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED) && <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_security_no"
              data-testid="lblOrderMemberIdCardSubscriberSSN"
            >
              {t("viewEligibilityForm.sssnLabel")}
            </label>
            <div>
              <InputField
                maxLength={9}
                type="text"
                {...register("subscriberSecurityNumber", {
                  setValueAs: (v) => ssnOncheck(v),
                  onChange: (e) => {
                    e.target.value = ssnOncheck(e.target.value);
                    trigger("searchInput");
                    resetField("memberId");
                    resetField("firstName");
                    resetField("lastName");
                  },
                  pattern: {
                    value: SSNRegex,
                    message: t("orderMemberIDCard.validationSSN"),
                  },
                })}
                forceNumber={true}
                className={
                  formState.errors.subscriberSecurityNumber?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("orderMemberIDCard.plcHolderSSN")}
                dataTestId="inputOrderMemberIdCardSubscriberSSN"
                id="order_id_security_no"
                aria-describedby="order_id_security_no_error"
              />
              <div
                className={
                  formState.errors.subscriberSecurityNumber?.message
                    ? ""
                    : "d-none"
                }
              >
                <div
                  id="order_id_security_no_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="subscriberSecurityNumber" />
                </div>
              </div>
            </div>
          </div>}
          <div className="pos-rel pt-2">
            <hr className="m-0" />
            <strong className="or-separator">
              {t("orderMemberIDCard.or")}
            </strong>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_last_name"
              data-testid="lblOrderMemberIdCardSubscriberLastName"
            >
              {userLob === USERTYPE.MEDICARE ? t("orderMemberIDCard.lastNameLabel") : t("orderMemberIDCard.subscriberLastName")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={20}
                {...register("lastName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("searchInput");
                    reset({
                       memberId: "",
                      subscriberSecurityNumber: "",
                      memberBeneficiaryIdentifier:"",

          
                    });
                  },
                })}
                className={
                  formState.errors.lastName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("orderMemberIDCard.plcLastName")}
                dataTestId="inputOrderMemberIdCardSubscriberLastName"
                id="order_id_last_name"
                aria-describedby="order_id_last_name_error"
              />
              <div
                className={formState.errors.lastName?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_last_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="lastName" />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label
              className="pb-1"
              htmlFor="order_id_first_name"
              data-testid="lblOrderMemberIdCardSubscriberFirstName"
            >
              {userLob === USERTYPE.MEDICARE ? t("orderMemberIDCard.firstNameLabel") : t("orderMemberIDCard.subscriberFirstName")}
            </label>
            <div>
              <InputField
                pattern={NameRegex}
                maxLength={20}
                type="text"
                forceChar={true}
                {...register("firstName", {
                  onChange: (e) => {
                    NameChange(e);
                    trigger("lastName");
                    reset({
                      //  memberId: "",
                      subscriberSecurityNumber: "",
                    });
                  },
                })}
                className={
                  formState.errors.firstName?.message
                    ? "form-input error-input-bdr"
                    : "form-input"
                }
                placeholder={t("orderMemberIDCard.plcFirstName")}
                dataTestId="inputOrderMemberIdCardSubscriberFirstName"
                id="order_id_first_name"
                aria-describedby="order_id_first_name_error"
              />
              <div
                className={formState.errors.firstName?.message ? "" : "d-none"}
              >
                <div
                  id="order_id_first_name_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="firstName" />
                </div>
              </div>
            </div>
          </div>
          {/* </>} */}
        </div>
      </div>
      <div className="mb-4">
        <div className="d-flex align-items-center mb-2">
          <InputField
            className="form-check-input mt-0 p-0"
            type="checkbox"
            id={"checkboxid"}
            {...register("checkboxAllMembers", {
              onChange: (e) => {
                trigger("searchInput");
              },
            })}
            data-testid={"chkOrderMemberIdCard100orLess"}
          />
          <label
            className="check-label-text check-label-pad"
            htmlFor={"checkboxid"}
          >
            {t("orderMemberIDCard.note")}
          </label>
        </div>
      </div>
      <hr className="mt-0 mb-4" />
      <OrderMemberIDCardSubmit
        handleClearForm={handleClearForm}
        isFormDirty={
          (userLob != USERTYPE.MEDICARE ?
            !(!getValues("memberId") &&
              !getValues("subscriberSecurityNumber") &&
              !getValues("lastName")) && getValues("groupId") :
            getValues("lastName") || (getValues("memberId") && !formState.errors.memberId?.message) || getValues("memberBeneficiaryIdentifier"))
        }

      />
    </div>
  );
}

export default OrderMemberIDCardForm;
