import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";
import { useEmployerNewRenew } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";
const EmployerNewRenewing = () => {
  const { user } = useAuth();
  const { state, getEmployerNewRenewSSO } = useEmployerNewRenew();

  useEffect(() => {
    document.title = t('pageTitleADA.employerNewAndRenewing');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID) {
      await getEmployerNewRenewSSO({ userID: user?.loginID });
    }
  };

  return (
    <SSONavigationContent
      header={t("sso.header.employerNewRenewing")}
      ssoUrl={state?.Result?.ssoUrl}
      dataTestId="ssoLinkEmployerNewAndRenewing"
      targetName="EmployerNewAndRenewing"
    />
  );
};
export default EmployerNewRenewing;
