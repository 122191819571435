import { useEffect } from "react";
import { t } from "i18next";
import { Image, Link } from "@sentaraui/optimahealth_web";
import { DEVICE_MANAGEMENT_PORTAL, DEVICE_MANAGEMENT, ENROLL_SMART_PHONE, ENROLL_TABLET, ENROLL_PHONE } from "utils/constants/Routes";
import { DEVICE_MANAGEMENT_IMAGE } from "utils/constants/Constants";
import { useBranding, useGlobalState } from "providers";
export const Enrollment = () => {
    const { setDmTitle } = useGlobalState();
    const { brandConfig } = useBranding();
    useEffect(() => {
        document.title = t('pageTitleADA.deviceManagementEnrollment');
        setDmTitle(t('deviceManagement.enrollmentLink'));
    }, [setDmTitle]);

    const EnrollmentList = [
        { content: t('deviceManagement.enrollment.content6'), enrollImagePath: '', altText: '' },
        { content: t('deviceManagement.enrollment.content7'), enrollImagePath: brandConfig.image.DuoStartSetupImg, altText: t('deviceManagement.enrollment.startSetUpImg') },
        { content: t('deviceManagement.enrollment.content8'), enrollImagePath: DEVICE_MANAGEMENT_IMAGE.image14, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
    ];
    const EnrollDeviceInfoLinkList = [
        { enrollInfoLink: t('deviceManagement.enrollment.content10'), enrollInfoLinkPath: `/${DEVICE_MANAGEMENT_PORTAL}/${ENROLL_SMART_PHONE}` },
        { enrollInfoLink: t('deviceManagement.enrollment.content11'), enrollInfoLinkPath: `/${DEVICE_MANAGEMENT_PORTAL}/${ENROLL_TABLET}` },
        { enrollInfoLink: t('deviceManagement.enrollment.content12'), enrollInfoLinkPath: `/${DEVICE_MANAGEMENT_PORTAL}/${ENROLL_PHONE}` },
    ];
    return (
        <div>
            <div className="d-flex flex-column gap-3 mb-4">
                <div>{t('deviceManagement.enrollment.content1')}</div>
                <ol className="d-flex flex-column gap-3">
                    <li>
                        <div className="mb-3">{t('deviceManagement.enrollment.content2')}</div>
                        <div>{t('deviceManagement.enrollment.content3')}</div>
                    </li>
                    <li>
                        <Link data-testid="lnkDeviceManagement" to={`/${DEVICE_MANAGEMENT_PORTAL}/${DEVICE_MANAGEMENT}`} target="_blank">
                            {t('deviceManagement.enrollment.content4')}
                        </Link>.{" "}
                        {t('deviceManagement.enrollment.content5')}
                    </li>

                    {EnrollmentList.map((item, index) => (
                        <li key={index}>
                            <div className="pb-1">{item.content}</div>
                            {item.enrollImagePath &&
                                <Image
                                    dataTestId={`imgEnroll_${index}`}
                                    path={item.enrollImagePath}
                                    alttext={item.altText}
                                    className="img-fluid"
                                />
                            }
                        </li>
                    ))}
                </ol>
                <div>
                    <div className="mb-3">{t('deviceManagement.enrollment.content9')}</div>
                    <ul className="d-flex flex-column gap-2">
                        {EnrollDeviceInfoLinkList.map((item, index) => (
                            <li key={index}>
                                <Link data-testid={`enrollInfoLink_${index}`} to={item.enrollInfoLinkPath}>
                                    {item.enrollInfoLink}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Enrollment;