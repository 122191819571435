import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import enSentara from "./en-sentara.json";
import enAvMed from "./en-avmed.json";
import { LOCALETYPE } from "utils/model/CommonTypes";

const resources = {
  enSentara,
  enAvMed,
};

i18n.use(initReactI18next).init({
  lng: LOCALETYPE.ENSENTARA,
  resources,
  fallbackLng: [LOCALETYPE.ENAVMED, LOCALETYPE.ENSENTARA],
});
