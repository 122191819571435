import React, { useState, useEffect, ChangeEvent } from "react";
import {
  PrimaryButton,
  SecondaryButton,
  Label,
  InputField,
  SelectDropdown,
  Link,
} from "@sentaraui/optimahealth_web";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VALIDATION_LENGTH, groupSizeItems } from "utils/constants/Constants";
import { FormError } from "../../organisms/form";
import {
  AlphaNumericComma,
  AlphaNumericSpace,
  EmailRegex,
  NumericHyphen,
} from "utils/constants/Regex";
import { NameChange, RemoveSpace, phoneCheck, pincodecheck } from "utils/helpers/HelperFn";
import { useAuth, useBranding } from "providers";

interface ContactUsFormProps {
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  dropdownValue: string;
}

const ContactUsForm = ({ handleChange, dropdownValue }: ContactUsFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    reset,
    formState: { errors, isValid },
    getValues,
  } = useFormContext();
  const { user } = useAuth();
  const { brandConfig } = useBranding();
  const [email, setEmail] = useState("");
  const handleClearForm = () => {
    reset();
  };
  useEffect(() => {
    setEmail(user?.emailAddresses?.[0]?.emailAddress || "");
  }, [user]);

  const isValidate = (isValid: boolean) => {
    if (isValid) {
      return !(
        getValues("isEmailOptIn") === "isEmailOptIn" ||
        getValues("isMailOptIn") === "isMailOptIn" ||
        getValues("IsPhoneOptIn") === "IsPhoneOptIn"
      )
        ? "primary_btn disabled_btn btn_block_mob"
        : `primary_btn btn_block_mob`;
    }
    return "primary_btn disabled_btn btn_block_mob";
  };

  const validateFields = (value: string, name: string): string | undefined => {
    if (getValues(name)?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validateEmail = (value: string, name: string) => {
    if (getValues(name)?.length !== 0 && value?.length < 2) {
      return t("contactus.emailOneLength", { value: value?.length });
    } else if (getValues(name)?.length !== 0 && value?.length < 5) {
      return t("contactus.emailMinLength", { value: value?.length });
    } else if (getValues(name)?.length !== 0 && !EmailRegex.test(value)) {
      return t("contactus.validationEmail");
    }
  };
  const validateContact = () => {
    if (
      getValues("isEmailOptIn") !== "isEmailOptIn" &&
      getValues("isMailOptIn") !== "isMailOptIn" &&
      getValues("IsPhoneOptIn") !== "IsPhoneOptIn"
    ) {
      return t("contactus.atLeastOneContact");
    }
  };

  register("organization", {
    validate: (value: string) => validateFields(value, "organization"),
  });
  register("licensedState", {
    validate: (value: string) => validateFields(value, "licensedState"),
  });
  register("address1", {
    validate: (value: string) => validateAddress(value),
  });
  register("phone", {
    validate: (value: string) => validatePhone(value),
  });

  register("postalCode", {
    validate: (value: string) => validatePostalCode(value),
  });

  register("cityState", {
    validate: (value: string) => validateCityState(value),
  });

  const validatePostalCode = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateZipCode");
      }
    }
  };

  const validateCityState = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateState");
      }
    }
    if (value && value?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validateAddress = (value: string) => {
    if (getValues("isMailOptIn") === "isMailOptIn") {
      if (!value) {
        return t("contactus.validateAddress");
      }
    }
    if (value && value?.length !== 0 && value?.length < 2) {
      return t("contactus.organizationMinLength", {
        value: value?.length,
      });
    }
  };

  const validatePhone = (value: string) => {
    if (getValues("IsPhoneOptIn") === "IsPhoneOptIn") {
      if (!value) {
        return t("contactus.requirePhone");
      }
    }
    if (value && value?.length !== 0 && value?.length < 2) {
      return `${t("contactus.validatePhone")} \n ${t("contactus.phoneOneLength", { value: value.length })}`;
    } else if (value && value?.length !== 0 && value?.length < 10) {
      return `${t("contactus.validatePhone")} \n ${t("contactus.phoneMinLength", { value: value.length })}`;
    }
  };

  register("contact", { validate: validateContact });

  const validClass = (name: string) => {
    if (errors[name]) {
      return "form-input error-input-bdr";
    }
    return "form-input";
  };

  const errorClass = (name: string) => {
    if (errors[name]) {
      return "error";
    }
    return "d-none";
  };
  const required = (< strong className="text-danger ps-1" aria-label={t('required')}>*</strong >);

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12">
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsFirstName"
                htmlFor="inputFirstName">
                {t("contactus.labelFirstName")}{required}
              </Label>
            </div>
            <InputField
              className="form-input"
              type="text"
              value={user?.person?.firstName}
              data-testid="inputContactUsFirstName"
              {...register("firstName")}
              id="inputFirstName"
              aria-describedby="inputFirstName_error"
              disabled={true}
            />
            <div
              id="inputFirstName_error"
              aria-live="polite"
              aria-atomic="true"
            >
              <FormError name="firstName" />
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsLastName"
                htmlFor="inputLastName">
                {t("contactus.labelLastName")}{required}
              </Label>
            </div>
            <div>
              <InputField
                className="form-input"
                value={user?.person?.lastName}
                type="text"
                id="inputLastName"
                {...register("lastName")}
                data-testid="inputContactUsLastName"
                aria-describedby="inputLastName_error"
                disabled={true}
              />
            </div>
            <div id="inputLastName_error" aria-live="polite" aria-atomic="true">
              <FormError name="lastName" />
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsOrgName"
                htmlFor="organization"
                children={t("contactus.labelOrganization")}
              />
            </div>
            <InputField
              pattern={AlphaNumericSpace}
              maxLength={VALIDATION_LENGTH.ORGANIZATION_MAX_LENGTH}
              placeholder={t("contactus.plcOrganization")}
              className={validClass("organization")}
              type="text"
              id="inputOrgName"
              {...register("organization", {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  RemoveSpace(e);
                },
              })}
              data-testid="inputContactUsOrgName"
              aria-describedby="organization_error"
            />
            <div className={errorClass("organization")}>
              <div
                id="organization_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="organization" />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsGrpSize"
                htmlFor="inputGroupSize"
                children={t("contactus.labelGroupSize")}
              />
            </div>
            <div>
              <SelectDropdown
                className={
                  errors.groupId?.message
                    ? " form-select error-input-bdr"
                    : "form-select"
                }
                data-testid="optContactUsGrpSize"
                name="groupSize"
                defaultValue=""
                onChange={handleChange}
                id="inputGroupSize"
              >
                <option value={dropdownValue}>
                  {t("contactus.selectGroup")}
                </option>

                {groupSizeItems.map((items: any, index: number) => (
                  <option value={items.value} key={index}>
                    {items.value}
                  </option>
                ))}
              </SelectDropdown>
            </div>
            <div className="granite-gray-color pt-1">
              <small>{t("contactus.groupSizeNotes")}</small>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsEmail"
                htmlFor="inputEmail">
                {t("contactus.labelEmail")}{required}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("email")}
                type="text"
                value={email}
                maxLength={VALIDATION_LENGTH.EMAIL_MAX_LENGTH}
                id="inputEmail"
                placeholder={t("contactus.emailPlcHolder")}
                data-testid="inputContactUsEmail"
                {...register("email", {
                  required: t("contactus.requiredEmail"),
                  validate: (value: string) => validateEmail(value, "email"),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  },
                })}
                aria-describedby="contactus_email_error"
              />
            </div>
            <div className={errorClass("email")}>
              <div
                id="contactus_email_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="email" />
              </div>
            </div>
            <div className="granite-gray-color pt-1">
              <small>
                {t("contactus.privacyPolicyContent1")}
                <Link
                  to={brandConfig.link.privacyPolicy}
                  target="_blank"
                  className="redhat_regular px-1"
                  data-testid="lnkPrivacyPolicy"
                >
                  {t("contactus.privacyPolicyLink")}
                </Link>
                {t("contactus.privacyPolicyContent2")}
              </small>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsAddress"
                htmlFor="address1">
                {t("contactus.labelAddress")}
                {getValues("isMailOptIn") === "isMailOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("address1")}
                type="text"
                id="address1"
                maxLength={VALIDATION_LENGTH.ADDRESS_MAX_LENGTH}
                placeholder={t("contactus.addressPlcHolder")}
                {...register("address1", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                data-testid="inputContactUsAddressLine1"
                aria-describedby="addressLine1_error"
              />
            </div>
            <div className={errorClass("address1")}>
              <div
                id="addressLine1_error"
                aria-live="polite"
                aria-atomic="true"
              >
                <FormError name="address1" />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <InputField
              className="form-input"
              type="text"
              maxLength={VALIDATION_LENGTH.ADDRESS_MAX_LENGTH}
              id="inputAddressLine2"
              placeholder={t("contactus.addressPlcHolder2")}
              {...register("address2", {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  RemoveSpace(e);
                },
              })}
              data-testid="inputContactUsAddressLine2"
            />
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsCityOrState"
                htmlFor="inputCityAndState">
                {t("contactus.labelCityOrState")}
                {getValues("isMailOptIn") === "isMailOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                className={validClass("cityState")}
                type="text"
                maxLength={VALIDATION_LENGTH.CITY_MAX_LENGTH}
                placeholder={t("contactus.cityPlcHolder")}
                id="inputCityAndState"
                {...register("cityState", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    NameChange(e);
                  },
                })}
                dataTestId="inputContactUsCityOrState"
                aria-describedby="cityOrState_error"
              />
              <div className={errorClass("cityState")}>
                <div
                  id="cityOrState_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="cityState" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsZipCode"
                htmlFor="inputZipOrPostalCode">
                {t("contactus.labelZipOrPostalCode")}
                {getValues("isMailOptIn") && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                pattern={NumericHyphen}
                placeholder={t("contactus.plcPostalCode")}
                className={validClass("postalCode")}
                type="tel"
                maxLength={VALIDATION_LENGTH.PHONENUMBER_MAX_LENGTH}
                id="inputZipOrPostalCode"
                {...register("postalCode", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    pincodecheck(e);
                  },
                })}
                dataTestId="inputContactUsZipCode"
                aria-describedby="zipCode_error"
              />
              <div className={errorClass("postalCode")}>
                <div id="zipCode_error" aria-live="polite" aria-atomic="true">
                  <FormError name="postalCode" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsPhoneNum"
                htmlFor="inputDaytimePhone">
                {t("contactus.labelDaytimePhone")}
                {getValues("IsPhoneOptIn") === "IsPhoneOptIn" && (
                  <span>{required}</span>
                )}
              </Label>
            </div>
            <div>
              <InputField
                placeholder={t("contactus.plcHolderPhone")}
                type="tel"
                maxLength={10}
                className={validClass("phone")}
                id="inputDaytimePhone"
                {...register("phone", {
                  validate: validatePhone,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    phoneCheck(e);
                  },
                })}
                data-testid="inputContactUsPhoneNum"
                forceNumber={true}
                aria-describedby="phone_error"
              />
              <div className={errorClass("phone")}>
                <div id="phone_error" aria-live="polite" aria-atomic="true">
                  <FormError name="phone" />
                </div>

                <div id="phone_error" aria-live="polite" aria-atomic="true">
                  <FormError name="phone1" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsLicensedStates"
                htmlFor="inputLicensedOrAppointedInState"
                children={t("contactus.labelLicensedOrAppointedInState")}
              />
            </div>
            <div>
              <InputField
                pattern={AlphaNumericComma}
                placeholder={t("contactus.plcState")}
                className={validClass("licensedState")}
                type="text"
                id="inputLicensedOrAppointedInState"
                maxLength={VALIDATION_LENGTH.CITY_MAX_LENGTH}
                {...register("licensedState", {
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    RemoveSpace(e);
                  },
                })}
                data-testid="inputContactUsLicensedStates"
                aria-describedby="licensedState_error"
              />
              <div className={errorClass("licensedState")}>
                <div
                  id="licensedState_error"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  <FormError name="licensedState" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsIWouldLikeInfoOn"
                htmlFor="inputWouldlikeInformationOn"
                children={t("contactus.labelWouldlikeInformationOn")}
              />
            </div>

            <div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isHealthProductsInformation"}
                  value={"isHealthProductsInformation"}
                  data-testid={"chkHealthProducts"}
                  {...register("isHealthProductsInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isHealthProductsInformation`}
                >
                  {t("contactus.healthProducts")}
                </label>
              </div>

              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isBehavioralHealthProductsInformation"}
                  value={"isBehavioralHealthProductsInformation"}
                  data-testid={"chkContactUsContactViaPhone"}
                  {...register("isBehavioralHealthProductsInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isBehavioralHealthProductsInformation`}
                >
                  {t("contactus.behavioralHealthProducts")}
                </label>
              </div>

              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isAppointmentInformation"}
                  value={"isAppointmentInformation"}
                  data-testid={"chkAppointmentInfo"}
                  {...register("isAppointmentInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isAppointmentInformation`}
                >
                  {t("contactus.appointmentInformation")}
                </label>
              </div>

              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isOtherInformation"}
                  value={"isOtherInformation"}
                  data-testid={"chkOthers"}
                  {...register("isOtherInformation")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isOtherInformation`}
                >
                  {t("contactus.other")}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsQuestion"
                htmlFor="inputQuestion">
                {t("contactus.labelQuestion")}{required}
              </Label>
            </div>
            <div>
              <textarea
                className={validClass("question")}
                rows={9}
                maxLength={VALIDATION_LENGTH.ORGANIZATION_MAX_LENGTH}
                id="inputQuestion"
                placeholder={t("contactus.questionPlcHolder")}
                {...register("question", {
                  required: t("contactus.requiredQuestion"),
                })}
                data-testid="inputContactUsQuestion"
                aria-describedby="question_error"
              />
              <div className={errorClass("question")}>
                <div id="question_error" aria-live="polite" aria-atomic="true">
                  <FormError name="question" />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsHowWouldLikeToContact"
                htmlFor="inputHowWouldLikeToContact">
                {t("contactus.labelHowWouldLikeToContact")}{required}
              </Label>
            </div>
            <div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"IsPhoneOptIn"}
                  value={"IsPhoneOptIn"}
                  data-testid={"chkContactUsContactViaPhone"}
                  {...register("IsPhoneOptIn")}
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`IsPhoneOptIn`}
                >
                  {t("contactus.phone")}
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isMailOptIn"}
                  value={"isMailOptIn"}
                  data-testid={"chkContactUsContactViaPhone"}
                  {...register("isMailOptIn")}
                />

                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isMailOptIn`}
                >
                  {t("contactus.mail")}
                </label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <InputField
                  className="form-check-input p-0"
                  type="checkbox"
                  id={"isEmailOptIn"}
                  value={"isEmailOptIn"}
                  data-testid={"chkContactUsContactViaEmail"}
                  {...register("isEmailOptIn")}
                  aria-describedby="emailOptIn_error"
                />
                <label
                  className="check-label-text check-label-pad"
                  htmlFor={`isEmailOptIn`}
                >
                  {t("contactus.email")}
                </label>
              </div>
            </div>
            <div className={errorClass("contact")}>
              <div id="emailOptIn_error" aria-live="polite" aria-atomic="true">
                <FormError name="contact" />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div>
              <Label
                className="pb-1"
                data-testid="lblContactUsIncludeAttachment"
                htmlFor="inputIncludeAttachment"
                children={t("contactus.labelIncludeAttachment")}
              />
            </div>
            <div>
              <input
                data-testid="inputContactUsIncludeAttachement"
                placeholder="Upload"
                className="text-decoration-none border-0"
                type="file"
                id="inputIncludeAttachment"
                {...register("fileAttachment")}
              />
            </div>
          </div>
        </div>
      </div>
      <div>{t("contactus.notes")}</div>
      <hr className="my-4" />
      <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4">
        <PrimaryButton
          data-testid="btnContactUsSubmit"
          id="primary"
          type="submit"
          name="submit"
          className={isValidate(isValid)}
          aria-label={t("contactus.submit")}
        >
          {t("contactus.submit")}
        </PrimaryButton>
        <SecondaryButton
          data-testid="btnContactUsClear"
          id="primary"
          type="button"
          variant={"secondary_btn btn_block_mob"}
          onClick={handleClearForm}
        >
          {t("button.label.clear")}
        </SecondaryButton>
      </div>
    </div>
  );
};

export default ContactUsForm;