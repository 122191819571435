import React, { useEffect } from "react";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";
import { useMediCareEnrollment } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const MedicareEnrollment = () => {
  const { user } = useAuth();
  const { state, getMediCareEnrollment } = useMediCareEnrollment();

  useEffect(() => {
    document.title = t('pageTitleADA.medicareEnrollment');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.person?.personID) {
      await getMediCareEnrollment({
        loginID:user?.loginID as string,
        personID: user?.person?.personID as string,
      });
    }
  };

  return (
    <>
      <SSONavigationContent
        header={t("sso.header.medicareEnrollment")}
        ssoUrl={state?.Result?.ssoUrl}
        dataTestId="ssoLinkMedicareEnrollment"
        targetName="MedicareEnrollment"
      />
    </>
  );
};
export default MedicareEnrollment;
