import { useTranslation } from "react-i18next";
import { TopNav } from "@sentaraui/optimahealth_web";
import { LinkListType } from "utils/model/CommonTypes";
import { useBranding } from "providers";

export const HeaderTopNav = () => {
  const { t } = useTranslation();
  const { brandConfig } = useBranding()

  return (
    <div>
      <TopNav
        topLinks={Object.keys(brandConfig.topNavLinks).map((key: string) => {
          const item: LinkListType = brandConfig.topNavLinks[key]
          return {
            ...item,
            url: item.to,
            dataTestId: item.id,
            label: t(item.title),
          };
        })}
      />
    </div>
  );
};
