import {
  FormatType,
  useIdentificationCard,
} from "@sentara/sentara-api-hooks-core";
import { MemberRelationship, listPerTable } from "utils/constants/Constants";
import {
  PrimaryButton,
  InputField,
  AccordionTable,
} from "@sentaraui/optimahealth_web";
import { useAuth, useGlobalState } from "providers";
import { pdfConverter, userTypeLob } from "utils/helpers/HelperFn";
import { t } from "i18next";
import {
  OrderIDList,
  OrderIDListItem,
  SelectedDepType,
  USERTYPE,
} from "utils/model/CommonTypes";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";
import { useState } from "react";

type Props = {
  isMobile?: boolean;
  pageList?: OrderIDList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
  isMedicare:boolean;
};

const SearchListItem = ({
  isMobile,
  pageList,
  page,
  selectedDep,
  setSelectedDep,
  isMedicare
}: Props) => {
  const { setPageLoader } = useGlobalState();
  const [openInfo, setOpenInfo] = useState<{
    open: boolean;
    content?: string;
    title?: string;
  }>({ open: false, content: "", title: "" });
  const { getIdentificationCard } = useIdentificationCard();

  const { user } = useAuth();

  const handleCheckboxMultipleChange = (
    id: string,
    ids: string[],
    checked: any
  ) => {
    let updated = selectedDep ?? {};
    if (updated && ids.length > 1) {
      ids.forEach((item) => {
        const { [item]: omited, ...rest } = updated;
        updated = rest;
      });
    }
    if (checked) {
      updated = { ...updated, [id]: ids };
    } else {
      const { [id]: omited, ...rest } = updated;
      updated = rest;
    }
    setSelectedDep(updated);
  };

  const openFile = (memberId: string) => {
    setPageLoader("getIdentificationCard", true);
    getIdentificationCard({ memberId, formatType: FormatType.PDF }).then(
      (res) => {
        setPageLoader("getIdentificationCard", false);
        if (res?.data?.files?.length) {
          res.data.files?.forEach((item) => {
            if (item?.data && res?.data?.id) {
              pdfConverter(item?.data);
            }
          });
        } else {
          setOpenInfo({ open: true, content: t("featureUnavailable") });
        }
      }
    );
  };

  const listDom = (isMedicare:boolean) => {
    if (!pageList) {
      return false;
    }
    let keyArray = Object.keys(pageList);
    keyArray.sort((a, b) => a.localeCompare(b));
    const start = (page - 1) * listPerTable;
    keyArray = keyArray.slice(start, start + listPerTable);
   

    return keyArray?.map((key) => {
      const member: OrderIDListItem = pageList[key];
      const medicareRow =()=>(
        <>
          <td className="checkbox-only text-center">
            <InputField
              id={`chkOrderMemberIdCardTable-${key}`}
              className="form-check-input mt-0 p-0"
              type="checkbox"
              disabled={
                selectedDep
                  ? !!selectedDep[key.split("*")[0]]?.includes(key)
                  : false
              }
              data-testid={`chkOrderMemberIdCardTableRowSearchData-${key}`}
              checked={
                selectedDep
                  ? selectedDep[key]?.length > 0 ||
                    !!selectedDep[key.split("*")[0]]?.includes(key)
                  : false
              }
              onChange={(e: any) => {
                handleCheckboxMultipleChange(key, [key], e.target.checked);
              }}
            />
          </td>
          <td>{key}</td>
          <td>{`${member.firstName} ${member.midName} ${member.lastName}`}</td>
          <td>
            {isMedicare ? t("orderMemberIDCard.member") : member.relationshipCode &&
              MemberRelationship[member.relationshipCode]}
          </td>
          <td>
            <PrimaryButton
              data-testid={`chkOrderMemberIdCardTableRowSearchDataPrintButton-${key}`}
              type="button"
              variant={"primary_btn btn_block_mob"}
              onClick={() => {
                openFile(key);
              }}
            >
              {t("orderMemberIDCard.viewPrintBtn")}
            </PrimaryButton>
          </td>
        </>
      )
      const dom = !isMedicare?(
        <tr key={key}>
          {Array.isArray(member) ? (
            <>
              {/* <td className="checkbox-only text-center">
                <InputField
                  id={`chkSelectSubsriberAndAllDependent_${key}}`}
                  className="form-check-input mt-0 p-0"
                  type="checkbox"
                  disabled={false}
                  data-testid={`chkOrderMemberIdCardTableRowSelectSubsriberAndAllDependent_${key}}`}
                  checked={
                    selectedDep
                      ? !!selectedDep[key.split("+")[0]]?.length
                      : false
                  }
                  onChange={(e: any) => {
                    handleCheckboxMultipleChange(
                      key.split("+")[0],
                      member,
                      e.target.checked
                    );
                  }}
                />
              </td>
              <td colSpan={5}>
                <label htmlFor={`chkSelectSubsriberAndAllDependent_${key}}`}>
                  {t("orderMemberIDCard.selectDependent")}
                  {" " + key.split("+")[0]}
                </label>
              </td> */}
            </>
          ) :medicareRow()}
        </tr>
      ):<tr key={key}>{medicareRow()}</tr>;


      return dom;
    });
  };

  const listDomMobile = (isMedicare:boolean) => {
    if (!pageList) {
      return [];
    }
    let keyArray = Object.keys(pageList);
    keyArray.sort((pageKeyA, pageKeyB) => pageKeyA.localeCompare(pageKeyB));
    const start = (page - 1) * listPerTable;
    keyArray = keyArray.slice(start, start + listPerTable);

    const list = keyArray?.map((key) => {
      const member: OrderIDListItem = pageList[key];

      return {
        title: (
          <div>
            {Array.isArray(member) ? (
              <div className="d-flex align-items-center flex-no-wrap gap-3">
                {/* <InputField
                  id={`orderMemberIdCardAllChk-${key}`}
                  className="form-check-input mt-0 p-0"
                  type="checkbox"
                  disabled={false}
                  data-testid="chkOrderMemberIdCardTableRowSelectSubsriberAndAllDependent"
                  checked={
                    selectedDep
                      ? !!selectedDep[key.split("+")[0]]?.length
                      : false
                  }
                  onChange={(e: any) => {
                    handleCheckboxMultipleChange(
                      key.split("+")[0],
                      member,
                      e.target.checked
                    );
                  }}
                />
                <label htmlFor={`orderMemberIdCardAllChk-${key}`}>
                  {t("orderMemberIDCard.selectDependent")}
                  {" " + key.split("+")[0]}
                </label> */}
              </div>
            ) : (
              <div className="d-flex align-items-center flex-no-wrap gap-3">
                <div>
                  <InputField
                    id={`orderMemberIdCardChk-${key}`}
                    className="form-check-input mt-0 p-0"
                    type="checkbox"
                    disabled={
                      selectedDep
                        ? !!selectedDep[key.split("*")[0]]?.includes(key)
                        : false
                    }
                    data-testid={`chkOrderMemberIdCardTableRowMobile-${key}`}
                    checked={
                      selectedDep
                        ? selectedDep[key]?.length > 0 ||
                          !!selectedDep[key.split("*")[0]]?.includes(key)
                        : false
                    }
                    onChange={(e: any) => {
                      handleCheckboxMultipleChange(
                        key,
                        [key],
                        e.target.checked
                      );
                    }}
                  />
                </div>
                <label htmlFor={`orderMemberIdCardChk-${key}`}>
                  <strong>{key}</strong>
                </label>
              </div>
            )}
          </div>
        ),
        id: "order_id_card_table_data",
        content: Array.isArray(member) ? (
          ""
        ) : (
          <div className="d-flex flex-column gap-3 pb-4">
            <div>
              <div>{t("orderMemberIDCard.name")}</div>
              <div>{`${member.firstName} ${member.midName} ${member.lastName}`}</div>
            </div>
            <div>
              <div>{t("orderMemberIDCard.relationship")}</div>
              <div>
            {isMedicare ? t("orderMemberIDCard.member") : member.relationshipCode &&
              MemberRelationship[member.relationshipCode]}
              </div>
            </div>
            <div>
              <PrimaryButton
                data-testid={`chkOrderMemberIdCardTableRowSearchDataPrintButton-${key}`}
                className="primary_btn btn-medium mw-auto btn_block_mob mb-6"
                onClick={() => {
                  openFile(key);
                }}
              >
                {t("orderMemberIDCard.viewPrintBtn")}
              </PrimaryButton>
            </div>
          </div>
        ),
      };
    });
    return list;
  };

  return (
    <>
      <ModalOverlayWithOutClose
        open={openInfo.open}
        callBack={() => setOpenInfo({ open: false })}
        title={openInfo.title}
        content={openInfo.content}
      />

      {isMobile ? (
        <AccordionTable
          initialActiveIndex={0}
          id="order_id_card_member_table"
          items={listDomMobile(isMedicare)}
        />
      ) : (
        <>{listDom(isMedicare)}</>
      )}
    </>
  );
};

export default SearchListItem;
