import { useEffect, useState } from "react";
import { Headings, Link } from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { useAuth, useBranding, useOidcAuth } from "providers";
import { SessionOut } from "pages/auth/SessionOut";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";
import { SERVICE_NUMBER } from "utils/constants/Constants";

const SignOut = () => {
  const { brandConfig } = useBranding();
  const [unAuthorizedUserPopup, setUnAuthorizedUserPopup] = useState(true);
  const { user, signOut } = useAuth();
  const { removeUser } = useOidcAuth();

  useEffect(() => {
    document.title = t("pageTitleADA.signOut");
  }, []);

  const handlePopupClose = () => {
    setUnAuthorizedUserPopup(false);
    removeUser();
    signOut();
  };

  return (
    <>
      {user?.isUnAuthorizedUser && (
        <ModalOverlayWithOutClose
          open={unAuthorizedUserPopup}
          callBack={() => handlePopupClose()}
          title={t("unAuthorizedUser.modalTitle")}
          content={t("unAuthorizedUser.modalDescription", {
            serviceNo: SERVICE_NUMBER.BROKER_SERVICE_NUMBER,
          })}
        />
      )}
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-3 mt-4">
          <Headings
            className="heading-one mb-4"
            dataTestId="txtSignOutTitle"
            level={1}
            text={t("signOut.signoutHeader")}
          />
          <div className="p-3 border border-radius10 mb-4 light-grey-bg">
            {t("signOut.signoutContent1")}
            <p className="pt-3 mb-0">{t("signOut.signoutContent2")}</p>
          </div>
          <Link to={brandConfig.homeLink} data-testid="lnkSignOutReturnToHome">
            {t("signOut.return_Home_Page")}
            <span aria-hidden="true">&nbsp;&raquo;</span>
          </Link>
        </div>
      </div>
      <SessionOut />
    </>
  );
};
export default SignOut;
