import { Headings } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";

export default function UnpaidGroupBalanceHeader() {
  const { t } = useTranslation();
  return (
    <div>
      <Headings
        className="mb-4"
        dataTestId="unPaidGroupBalance_heading"
        level={1}
        text={t("page.title.unpaidGroupBalance")}
      />
    </div>
  );
}
