import { useGlobalState } from "providers";
import { t } from "i18next";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";

export const SessionOut = () => {
  const { inActiveSignOut, setInActiveSignOut } = useGlobalState();

  return (
    <ModalOverlayWithOutClose
      open={inActiveSignOut}
      callBack={() => setInActiveSignOut(false)}
      title={t("session.label.modalTitle")}
      content={t("session.label.logoutdesc")}
    />
  );
};
