import { t } from "i18next";
import SearchListItem from "./SearchListItem";
import { OrderIDList, SelectedDepType } from "utils/model/CommonTypes";

type Props = {
  pageList?: OrderIDList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
  getIdentificationCard: Function;
  isMedicare:boolean;
};

const SearchTableDeskTop = ({
  pageList,
  selectedDep,
  page,
  setSelectedDep,
  getIdentificationCard,
  isMedicare,
}: Props) => {
  return (
    <div className="table-responsive">
      <table
        className="table table-custom-style align-middle"
        data-testid="tblOrderMemberIdCardSearchResults"
      >
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">{t("orderMemberIDCard.memberID")}</th>
            <th scope="col">{t("orderMemberIDCard.name")}</th>
            <th scope="col">{t("orderMemberIDCard.relationship")}</th>
            <th scope="col"> </th>
          </tr>
        </thead>
        <tbody>
          {pageList ?
            <SearchListItem
              {...{
                pageList,
                selectedDep,
                page,
                setSelectedDep,
                getIdentificationCard,
                isMedicare
              }}
            />
            :
            <tr className="text-center">
              <td colSpan={5}>{t("noResult")}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default SearchTableDeskTop;
