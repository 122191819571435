import { BaseSyntheticEvent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { focusTo } from "utils/helpers/HelperFn";

type Props = {
    defaultValues: { [x: string]: number | string | boolean },
    onSubmit: (v: Object, e?: BaseSyntheticEvent<object, any, any> | undefined) => void,
    onSubmitError?: (v?: Object, e?: BaseSyntheticEvent<object, any, any> | undefined) => void,
    children: React.ReactNode
}
export const Form = (props: Props) => {
    const { defaultValues = {}, onSubmit, onSubmitError = () => { }, children } = props
    const methods = useForm(
        {
            defaultValues: defaultValues
        }
    )
    const { handleSubmit } = methods

    const _onSubmit = handleSubmit((formData, e) => onSubmit(formData, e), ((errors, e) => onSubmitError(errors, e)))

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => {
                focusTo()
                _onSubmit(e);
            }}
                data-testid="form-dom"
                noValidate 
                autoComplete="off">
                {children}
            </form>
        </FormProvider>
    )
}
