import "./assets/styles/Main.scss";
import "bootstrap/dist/js/bootstrap.js";
import { Routers } from "./routes";
import { AuthProvider, StateProvider, BrandingProvider } from "providers";

function App() {
  return (
    <BrandingProvider>
      <StateProvider>
        <AuthProvider>
          <Routers />
        </AuthProvider>
      </StateProvider>
    </BrandingProvider>
  );
}

export default App;
