import {
  Carrier,
  EmailAddresses,
  HasR2WAccess,
  Lobs,
  Person,
  RelationshipCode,
  UserGroup,
} from "@sentara/sentara-api-hooks-core";
import { JwtPayload } from "jwt-decode";

export type ENVTYPES = {
  BASE_URL: string;
  OPC_APIM_SUB_KEY: string;
};

export type FooterNavType = {
  dataTestId: string;
  label: string;
  url: string;
  target?: string;
};

export type UserType = {
  loginID: string;
  isUnAuthorizedUser: boolean;
  isSORCurrent?: boolean;
  hasR2WAccess?: HasR2WAccess;
  groupReportingDynamicLinkEnabled?: boolean;
  carrier?: Carrier;
  person?: Person;
  groups?: Array<UserGroup>;
  lobs?: Array<Lobs>;
  emailAddresses?: Array<EmailAddresses>;
  brokerType?: BROKERTYPE;
  npnNumber?: string;
};

export type ApiTypeModel = { type: string; endPoint?: string };
export type LinkListType = {
  title: string;
  to: string;
  id?: string;
  ariaLabel?: any;
  target?: string;
  avmedTo?: string;
  avmedTarget?:string;
  url?:string;
};

export type DeviceTypeProps = "desktop" | "tablet" | "mobile";

export const DeviceTypes = {
  DESKTOP: "desktop" as DeviceTypeProps,
  TABLET: "tablet" as DeviceTypeProps,
  MOBILE: "mobile" as DeviceTypeProps,
};

export enum LOCALETYPE {
  ENAVMED = "enAvMed",
  ENSENTARA = "enSentara",
}

export enum BROKERTYPE {
  COMBINED = 1,
  MEDICARE = 2,
  COMMERCIAL = 3
}

export type SelectedDep = {
  [x: string]: Array<string> | null;
};

export type OrderIDListItem = {
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  midName: string;
  relationshipCode: RelationshipCode;
};

export type OrderIDList = {
  [x: string]: OrderIDListItem;
};

export type SelectedDepType = {
  [x: string]: string[];
};

export type IdRequestType = {
  success: Array<string>;
  pending: Array<string>;
  failed: Array<string>;
};

export interface JwtPayloadExtended extends JwtPayload {
 group?: string;
}
export enum USERTYPE {
  COMMERCIAL = "Commercial",
  MEDICARE = "Medicare",
  COMBINED = "commercialMedicare"
}
export enum BRANDTYPE {
  AVMED = "_AvMed",
  SENTARA = "",
}
export enum MedicareType {
  MEDICARE = "_Medicare",
}

