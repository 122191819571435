import { BrokerMasterDetailsResult } from "@sentara/sentara-api-hooks-core";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { USERTYPE } from "utils/model/CommonTypes";

export const focusTo = () => {
  setTimeout(() => {
    const element = document.querySelector<HTMLElement>("[role=alert]");
    if (
      element?.classList.contains("alert-global") ||
      element?.classList.contains("global-alert")
    ) {
      element?.scrollIntoView({ behavior: "smooth" });
      element.focus();
    }
  }, 100);
};

export const printDiv = (pdfref: any) => {
  setTimeout(() => {
    const printhtml = pdfref.current?.innerHTML || "";
    const div = document.createElement("div");
    div.classList.add("print-div");
    div.innerHTML = `<div >${printhtml}</div>`;
    let nodes = [div];
    document.body.prepend(...nodes);
    window.print();
    setTimeout(() => {
      const ele = document.querySelector(`.print-div`) as HTMLDivElement;
      ele.remove();
    }, 300);
  }, 300);
};

export const NameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  let value = "";
  if (e.target.value.startsWith(" ")) {
    value = e.target.value.replace(" ", "");
  } else {
    value = e.target.value.replace(/(?!^)\s+/g, " ").replace(/[^A-Za-z ]/g, "");
  }
  e.target.value = value;
};

export const RemoveSpace = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.preventDefault();
  if (e.target.value.startsWith(" ")) {
    e.target.value = e.target.value.replace(" ", "");
  }
};

export const pincodecheck = (e: any) => {
  let value = e.target.value;
  value = value.replace(/-/g, "");
  if (value.length > 5) {
    value = value.slice(0, 5) + "-" + value.slice(5);
  }
  e.target.value = value;
};

export const pdfConverter = (data: string, name?: string) => {
  // converting the base64 resposne to pdf
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: "application/pdf",
    endings: "native",
  });
  const pdfUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = pdfUrl;
  if (name) {
    link.download = `${name}.pdf`;
  } else {
    link.target = "_blank";
  }

  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );
};

export const toTwoDecimalPlaces = (value: number) => {
  return (Math.round(value * 100) / 100).toFixed(2);
};

export const amountFormat = (value: number, dollar = false) => {
  return dollar ? `$${toTwoDecimalPlaces(value)}` : toTwoDecimalPlaces(value);
};

export interface JwtPayloadExtended extends JwtPayload {
  group?: string;
 }

export const isUnAuthorizedUser = (
  brokerMasterDetailsResult: BrokerMasterDetailsResult,
  brandName: string,
  token?: string  
) => {
  
  if (token)
  {
    let decodedToken : JwtPayloadExtended = jwtDecode(token );


    if (brandName === "AVMED" && !decodedToken.group?.includes("PTL_AVMED_BROKER"))
    {
      return true;
    }
    else if (brandName === "Default" && !decodedToken.group?.includes("PTL_OHW_BROKER"))
    {
      return true
    }
  }
  else
  {
    // No user token, therefore unauthorized
    return true
  }

  if (
    !brokerMasterDetailsResult.lobs ||
    !brokerMasterDetailsResult.lobs.length
  ) {
    return true;
  }

  return false;
};

export const ssnOncheck = (v: any) => {
  let value = v;
  value = value.replace(/-/g, "");
  if (value.length > 3) {
    value = value.slice(0, 3) + "-" + value.slice(3);
  }
  if (value.length > 6) {
    value = value.slice(0, 6) + "-" + value.slice(6);
  }
  return value;
};

export const phoneCheck = (e: any) => {
  let value = e.target.value;
  value = value.replace(/-/g, "");
  if (value.length > 3) {
    value = value.slice(0, 3) + "-" + value.slice(3);
  }
  if (value.length > 7) {
    value = value.slice(0, 7) + "-" + value.slice(7);
  }
  e.target.value = value;
};

export const userTypeLob=(user:any)=>{
  let userType = "";
  const types = user?.lobs.map((lob: { name: string; }) => lob.name);
  if (types.includes('Medicare') && types.length > 1) {
    userType = USERTYPE.COMBINED;
  } else if (types.includes('Medicare')) {
    userType = USERTYPE.MEDICARE;
  } else {
      userType = USERTYPE.COMMERCIAL;
  }
return userType;
}
