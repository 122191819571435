import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { BROKER_DASHBOARD } from "utils/constants/Routes";
import { useBrokerMasterDetails, Carrier } from "@sentara/sentara-api-hooks-core";
import { useAuth, useGlobalState, useOidcAuth, useBranding } from "providers";
import { isUnAuthorizedUser } from "utils/helpers/HelperFn";


interface Prop {
  children: React.ReactNode;
  auth?: boolean;
}

export const ProtectedRoute = (prop: Prop) => {
  const { getBrokerMasterDetails } = useBrokerMasterDetails();
  const { setPageLoader } = useGlobalState();
  const { user: userODIC, removeUser } = useOidcAuth();
  const { user, isAccess, accessFeatures, updateUser, updateToken, signOut } =
    useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { brandConfig } = useBranding();

  const redirectToLogin = () => {
    const { pathname, search } = location;
    sessionStorage.setItem("redirectTo", `${pathname}${search}`);
    navigate("/");
  };

  useEffect(() => {
    const callApi = async (loginID: string) => {
      setPageLoader("brokerMasterDetails", true);
      const brokerDetails = await getBrokerMasterDetails(loginID);
      setPageLoader("brokerMasterDetails", false);

      if (brokerDetails?.data?.result) {

        const _isUnAuthorizedUser = isUnAuthorizedUser(
          brokerDetails.data.result,
          brandConfig.brandName,
          userODIC?.access_token
        );

        if (brandConfig.brandName === "AVMED") {
          let carrier: Carrier = { carrierID: 2, carrierName: "Avmed", carrierShortName: "Avmed", effectiveDate: "", endDate: "" }

          updateUser({
            ...user,
            ...brokerDetails.data.result,
            isUnAuthorizedUser: _isUnAuthorizedUser,
            carrier
          });
        }
        else {
          let carrier: Carrier = { carrierID: 1, carrierName: "Sentara", carrierShortName: "Sentara", effectiveDate: "", endDate: "" }

          updateUser({
            ...user,
            ...brokerDetails.data.result,
            isUnAuthorizedUser: _isUnAuthorizedUser,
            carrier
          });
        }



        if (_isUnAuthorizedUser) {
          navigate("/logout");
        } else if (!brokerDetails.data.result.isSORCurrent) {
          redirectToLogin();
        }
      } else {
        updateUser({
          ...user,
          isUnAuthorizedUser: true,
        });
        navigate("/logout");
      }
    };

    if (user?.loginID && !user.person && !user.isUnAuthorizedUser) {
      callApi(user?.loginID);
    }
  }, [user]);

  useEffect(() => {
    if (userODIC?.access_token && userODIC?.profile?.upn && !user?.loginID) {
      updateUser({
        ...{
          loginID: (userODIC?.profile?.upn as string).toString().split("@")[0],
          userDetailsFromOidc: userODIC,
        },
      });
      updateToken(userODIC?.access_token);
    } else if (userODIC?.id_token && !user) {
      removeUser();
      signOut("/");
    }
  }, [userODIC]);

  useEffect(() => {
    if (location && accessFeatures) {
      let { pathname } = location;
      pathname = sessionStorage.getItem("redirectTo") ?? pathname;
      sessionStorage.removeItem("redirectTo");

      if (!isAccess(pathname)) {
        navigate(`/app/${BROKER_DASHBOARD}`);
      }
      if (pathname === "/app") {
        navigate(`/app/${BROKER_DASHBOARD}`);
      }
    }
  }, [accessFeatures]);

  useEffect(() => {
    if (userODIC !== undefined || (!user && userODIC === undefined)) {
      if (!userODIC && !user?.isSORCurrent) {
        redirectToLogin();
      }
    }
  }, [userODIC]);

  return <>{prop.children}</>;
};
