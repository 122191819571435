import {
  Headings,
  PrimaryButton,
  RightArrowIcon,
  Link,
  Button,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { useEffect } from "react";
import { useBrokerRewards } from "@sentara/sentara-api-hooks-core";
import {
  brokerRewardsFAQ,
  mailLink,
  memberBaseUrl,
  programDetails,
} from "utils/constants/Navigation";
import { useAuth } from "providers";

const BrokerRewardsProgram = () => {
  const { user } = useAuth();
  const { getBrokerRewards, state: brokerRewards } = useBrokerRewards();

  useEffect(() => {
    document.title = t("pageTitleADA.brokerRewardsProgram");
    fetchOptSSO();
  }, []);

  const fetchOptSSO = async () => {
    if (user?.person?.personID) {
      await getBrokerRewards({ personID: user?.person?.personID as string });
    }
  };
  const OptInRewardsLink = () => {
    window.open(brokerRewards?.Result.optinURL, "_blank", "noopener");
  };
  const maritsFormSubmission = () => {
    const brokerReward = brokerRewards?.Result;
    if (brokerReward) {
      const fields: any = {
        redirectToken: brokerRewards.Result.redirectToken,
        clientProjectKey: brokerReward.clientProjectKey,
        ClientIdentifier: brokerReward.clientIdentifier,
        PartnerIdentifier: brokerReward.partnerIdentifier,
      };
      const maritsForm = document.createElement("form");
      maritsForm.setAttribute("method", "POST");
      maritsForm.setAttribute("action", brokerRewards.Result.redirectURL);
      maritsForm.setAttribute("target", "brokerPortalBrokerRewardProgram");
      for (const i in fields) {
        const field = document.createElement("input");
        field.type = "hidden";
        field.name = i;
        field.value = fields[i];
        maritsForm.appendChild(field);
      }
      document.body.appendChild(maritsForm);
      maritsForm.submit();
      document.body.removeChild(maritsForm);
    }
  };
  return (
    <div>
      <Headings
        className="mb-4"
        dataTestId="txtBrokerRewardProgramHeading"
        level={1}
        text={t("page.title.brokerRewardsProgram")}
      />

      <div className="border light-grey-bg border-radius10 p-3 mb-4">
        <div
          className="font-eighteen mb-3"
          data-testid="txtBrokerRewardProgramSubHeading1"
        >
          <strong>{t("brokerRewardsProgram.subTitle.sellAPlan")}</strong>
        </div>
        <div className="d-flex flex-column gap-3">
          <div>{t("brokerRewardsProgram.content.descText1")}</div>
          <div>{t("brokerRewardsProgram.content.descText2")}</div>
          {brokerRewards?.Result.accountExists && (
            <div className="d-flex align-items-center">
              <Button
                onClick={() => maritsFormSubmission()}
                data-testid="btnBrokerRewardProgramFormSubmit"
                variant="btn btn-link p-0 px-0 border-0 text-decoration-none bg-transparent"
              >
                <strong>
                  {t("brokerRewardsProgram.link.exploreYourRewards")}
                </strong>
                <span className="green-color-stroke" aria-hidden="true">
                  <RightArrowIcon />
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="black-color">
        <div className="mb-4">
          <Headings
            className="redhat_medium font-twentyfour mb-3"
            dataTestId="txtBrokerRewardProgramSubHeading2"
            level={2}
            text={t("brokerRewardsProgram.subTitle.getStarted")}
          />
          <ul className="list-disc d-flex flex-column gap-3 ps-4">
            <li>{t("brokerRewardsProgram.content.list1")}</li>
            <li>{t("brokerRewardsProgram.content.list2")}</li>
            <li>{t("brokerRewardsProgram.content.list3")}</li>
          </ul>
          <PrimaryButton
            data-testid="btnBrokerRewardProgramOptInToRewards"
            id="primary"
            onClick={OptInRewardsLink}
            className="primary_btn btn_block_mob"
          >
            {t("button.label.optInRewards")}
          </PrimaryButton>
        </div>

        <div className="mb-4">
          <Headings
            className="redhat_medium font-twentyfour mb-3"
            dataTestId="txtBrokerRewardProgramSubHeading3"
            level={2}
            text={t("brokerRewardsProgram.subTitle.startShopping")}
          />
          <p>{t("brokerRewardsProgram.content.descText3")}</p>
          <ul
            className="list-disc d-flex flex-column gap-3 ps-4"
            data-testid="startShopping_content"
          >
            <li>{t("brokerRewardsProgram.content.list4")}</li>
            <li>{t("brokerRewardsProgram.content.list5")}</li>
            <li>{t("brokerRewardsProgram.content.list6")}</li>
            <li>{t("brokerRewardsProgram.content.list7")}</li>
            <li>{t("brokerRewardsProgram.content.list8")}</li>
            <li>{t("brokerRewardsProgram.content.list9")}</li>
          </ul>
        </div>

        <div>
          <Headings
            className="redhat_medium font-twentyfour mb-3"
            dataTestId="txtBrokerRewardProgramSubHeading4"
            level={2}
            text={t("brokerRewardsProgram.subTitle.keepTrack")}
          />
          <p className="mb-4">{t("brokerRewardsProgram.content.descText4")}</p>
        </div>

        <div>
          <Headings
            className="redhat_medium font-twentyfour mb-3"
            dataTestId="txtBrokerRewardProgramSubHeading5"
            level={2}
            text={t("brokerRewardsProgram.subTitle.programRules")}
          />
          <p>{t("brokerRewardsProgram.content.descText5")}</p>
          <p className="mb-4">
            <Link
              to={programDetails}
              className="link-blue"
              data-testid="lnkBrokerRewardProgramSeeProgramDetails"
            >
              {t("brokerRewardsProgram.link.seeProgramDetails")}
            </Link>
          </p>
        </div>

        <div>
          <Headings
            className="redhat_medium font-twentyfour mb-3"
            dataTestId="txtBrokerRewardProgramSubHeading6"
            level={2}
            text={t("brokerRewardsProgram.subTitle.termsAndConditions")}
          />
          <ul
            className="list-disc d-flex flex-column gap-3 ps-4 ms-1"
            data-testid="termsAndConditions_content"
          >
            <li>
              {t("brokerRewardsProgram.terms.list1Text1")}{" "}
              <Link
                to={memberBaseUrl}
                target="_blank"
                rel="noopener"
                className="link-blue"
                data-testid="lnkBrokerRewardProgramSenHealthPlans"
              >
                {t("brokerRewardsProgram.terms.list1Link")}
              </Link>
              , {t("brokerRewardsProgram.terms.list1Text2")}
            </li>
            <li>{t("brokerRewardsProgram.terms.list2")}</li>
            <li>
              {t("brokerRewardsProgram.terms.list3Text1")}{" "}
              <Link
                to={mailLink}
                className="link-blue"
                data-testid="lnkBrokerRewardProgramBrokerRelationsEmail1"
              >
                {t("brokerRewardsProgram.link.brokerRelationsEmail")}
              </Link>{" "}
              {t("brokerRewardsProgram.terms.list3Text2")}
            </li>
            <li>{t("brokerRewardsProgram.terms.list4")}</li>
            <li>{t("brokerRewardsProgram.terms.list5")}</li>
            <li>{t("brokerRewardsProgram.terms.list6")}</li>
            <li>{t("brokerRewardsProgram.terms.list7")}</li>
            <li>{t("brokerRewardsProgram.terms.list8")}</li>
            <li>{t("brokerRewardsProgram.terms.list9")}</li>
            <li>{t("brokerRewardsProgram.terms.list10")}</li>
            <li>{t("brokerRewardsProgram.terms.list11")}</li>
            <li>{t("brokerRewardsProgram.terms.list12")}</li>
            <li>{t("brokerRewardsProgram.terms.list13")}</li>
            <li>{t("brokerRewardsProgram.terms.list14")}</li>
            <li>{t("brokerRewardsProgram.terms.list15")}</li>
            <li>{t("brokerRewardsProgram.terms.list16")}</li>
          </ul>
          <p>
            {t("brokerRewardsProgram.content.descText6")}{" "}
            <Link
              to={mailLink}
              className="link-blue"
              data-testid="lnkBrokerRewardProgramBrokerRelationsEmail2"
            >
              {t("brokerRewardsProgram.link.brokerRelationsEmail")}
            </Link>
          </p>
          <p className="pb-4">
            <Link
              to={brokerRewardsFAQ}
              target="_blank"
              rel="noopener"
              className="link-blue"
              data-testid="lnkBrokerRewardProgramFAQ"
            >
              {t("brokerRewardsProgram.link.FAQ")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default BrokerRewardsProgram;
