import { useEffect } from "react";
import { t } from "i18next";
import { Image } from "@sentaraui/optimahealth_web";
import { DEVICE_MANAGEMENT_IMAGE } from "utils/constants/Constants";
import ScrollToTop from "organisms/layouts/ScrollTop";
import { useGlobalState } from "providers";


const AutoPushLandline = () => {
    const AutoPushLandlineContent1 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent10')}
        </div>
    );
    const AutoPushLandlineContent2 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent19')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent35')}</strong>,{" "}
            {t('deviceManagement.enrollment.smartPhoneContent36')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent9')}</strong>.
        </div>
    );
    const AutoPushLandlineContent3 = (
        <div>
            {t('deviceManagement.overview.content12')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent38')}</strong>
        </div>
    );
    const AutoPushLandlineContent4 = (
        <div>
            {t('deviceManagement.overview.content12')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent39')}</strong>.
        </div>
    );
    const AutoPushLandlineList = [
        { AutoPushLandlineContent: AutoPushLandlineContent1, AutoPushLandlineImagePath: '', altText: '' },
        { AutoPushLandlineContent: AutoPushLandlineContent2, AutoPushLandlineImagePath: '', altText: '' },
        { AutoPushLandlineContent: AutoPushLandlineContent3, AutoPushLandlineImagePath: DEVICE_MANAGEMENT_IMAGE.image38, altText: t('deviceManagement.enrollment.startSetUpImg') },
        { AutoPushLandlineContent: AutoPushLandlineContent4, AutoPushLandlineImagePath: DEVICE_MANAGEMENT_IMAGE.image39, altText: t('deviceManagement.enrollment.startSetUpImg') },
    ];
    return (<div className="d-flex flex-column gap-3">
        <div>
            <strong>{t('deviceManagement.enrollment.phoneContent8')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent31')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent9')}</strong>.
        </div>
        <div className="text-danger">
            <span className="text-decoration-underline">{t('deviceManagement.faq.question10Answer2')}</span>{" "}
            {t('deviceManagement.faq.question10Answer3')}
        </div>
        <ol className="list-alpha d-flex flex-column gap-3">
            {AutoPushLandlineList.map((item, index) => (
                <li key={index}>
                    <div className="pb-1">{item.AutoPushLandlineContent}</div>
                    {item.AutoPushLandlineImagePath &&
                        <Image
                            dataTestId={`imgAutoPushLandline_${index}`}
                            path={item.AutoPushLandlineImagePath}
                            alttext={item.altText}
                            className="img-fluid"
                        />
                    }
                </li>
            ))}
        </ol>
    </div>
    )
}

export const EnrollPhone = () => {
    const { setDmTitle } = useGlobalState();
    useEffect(() => {
        if (setDmTitle) {
            setDmTitle(t('deviceManagement.enrollment.enrollPhoneHeading'));
        }
    }, [setDmTitle]);

    const EnrollPhoneContent1 = (
        <div>
            <strong>{t('deviceManagement.enrollment.phoneContent2')}</strong>{" "}
            {t('deviceManagement.enrollment.phoneContent3')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent4')}</strong>.{" "}
            {t('deviceManagement.enrollment.phoneContent5')}
        </div>
    );
    const EnrollPhoneContent2 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent6')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent4')}</strong>.{" "}
        </div>
    );
    const EnrollPhoneContent3 = (
        <div>
            <strong>{t('deviceManagement.enrollment.smartPhoneContent28')}</strong>{" "}
            {t('deviceManagement.enrollment.phoneContent7')}
        </div>
    );
    const EnrollPhoneContent4 = (
        <AutoPushLandline />
    );
    const EnrollPhoneContent5 = (
        <div>
            {t('deviceManagement.overview.content12')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent39')}</strong>{" "}
            {t('deviceManagement.enrollment.phoneContent11')}
        </div>
    );
    const EnrollPhoneContent6 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent7')}
        </div>
    );
    const EnrollPhoneContent7 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent12')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent13')}</strong>{" "}
            {t('deviceManagement.enrollment.phoneContent14')}
        </div>
    );
    const EnrollPhoneContent8 = (
        <div>
            {t('deviceManagement.enrollment.phoneContent15')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent16')}</strong>{" "}
            {t('deviceManagement.enrollment.phoneContent17')}{" "}
            <strong>{t('deviceManagement.enrollment.phoneContent18')}</strong>.
        </div>
    );
    const EnrollPhoneList = [
        { enrollPhoneContent: EnrollPhoneContent1, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image36, altText: t('deviceManagement.enrollment.startSetUpImg') },
        { enrollPhoneContent: EnrollPhoneContent2, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image37, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent3, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image43, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent4, enrollPhoneImagePath: '', altText: '' },
        { enrollPhoneContent: EnrollPhoneContent5, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image43, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent6, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image40, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent7, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image41, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent8, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image42, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
    ];
    return (
        <div>
            <ScrollToTop />
            <div className="font-eighteen mb-3"><strong>{t('deviceManagement.enrollment.phoneContent1')}</strong></div>
            <ol className="d-flex flex-column gap-3">
                {EnrollPhoneList.map((item, index) => (
                    <li key={index}>
                        <div className="pb-1">{item.enrollPhoneContent}</div>
                        {item.enrollPhoneImagePath &&
                            <Image
                                dataTestId={`imgEnrollPhone_${index}`}
                                path={item.enrollPhoneImagePath}
                                alttext={item.altText}
                                className="img-fluid"
                            />
                        }
                    </li>
                ))}
            </ol>
        </div>
    )
}
export default EnrollPhone;