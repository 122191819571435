import { useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { t } from "i18next";
import { Loader } from "@sentaraui/optimahealth_web";
import { AppHeader, AppFooter } from "./";
import IdleTimeoutContainer from "organisms/IdleTimeout/IdleTimerContainer";
import { useGlobalState, useAuth } from "providers";
import { DeviceTypes } from "utils/model/CommonTypes";
import { SideMenu } from "organisms/sidemenu";
import ScrollToTop from "./ScrollTop";

export const AppLayout = () => {
  const { accessFeatures } = useAuth();
  const { deviceType } = useGlobalState();
  const { loader } = useGlobalState();
  const { user } = useAuth();
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set focus on the main content element when the page load
    if (mainContentRef.current) {
      mainContentRef.current.focus();
    }
  }, []);

  return (
    <>
      {loader && <Loader />}
      <ScrollToTop />
      <div
        className="d-flex flex-column h-100"
        tabIndex={-1}
        ref={mainContentRef}
      >
        {/* Skip to Main Content link */}
        <a href="#main-content" className="skip-link" tabIndex={1}>
          {t("accessibility.message.skipToMainContent")}
        </a>
        <div>
          <AppHeader />
          <main id="main-content" className="container-fluid" role="main">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 d-none d-lg-block mb-4 home_page">
                {deviceType === DeviceTypes.DESKTOP && user?.person && (
                  <SideMenu />
                )}
              </div>
              <div
                className="col-lg-8 col-md-12 col-sm-12 col-12 mt-3"
                aria-live="polite"
              >
                {accessFeatures && user?.person && <Outlet />}
              </div>
            </div>
          </main>
        </div>
        <AppFooter />
      </div>
      <IdleTimeoutContainer></IdleTimeoutContainer>
    </>
  );
};
