import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  UserIconBlack,
  ModalOverlayHamburger,
  HamburgerIcon,
  ButtonLink,
} from "@sentaraui/optimahealth_web";
import { DeviceTypes } from "utils/model/CommonTypes";
import { CloseIconBlock, MailIcon } from "organisms/icons";
import { MenuAccordion } from "organisms/sidemenu";
import { NavLink, useNavigate } from "react-router-dom";
import { CONTACTUS } from "utils/constants/Routes";
import { useBranding, useGlobalState, useAuth, useOidcAuth } from "providers";
/**
 * Hamburger menu props
 */

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const { deviceType } = useGlobalState();
  const { signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { brandConfig } = useBranding();
  const { removeUser } = useOidcAuth();

  return (
    <>
      {deviceType !== DeviceTypes.DESKTOP && (
        <div>
          <button
            className="btn-link border-0 bg-transparent text-decoration-none ms-3"
            type="button"
            aria-label={t("button.label.toggleNav")}
            onClick={() => setIsOpen(true)}
            data-testid="hamburgerIconButton"
          >
            <HamburgerIcon />
          </button>
          <ModalOverlayHamburger
            isOpen={isOpen}
            onClose={() => { }}
            onRequestClose={() => setIsOpen(false)}
            data-testid="hamburgerClose"
            contentLabel={t("hamburger.label.overlayInfo")}
          >
            <div className="hamburger-menu-overlay">
              <div className="pt-4 p-3 pb-2">
                <button
                  type="button"
                  data-testid="hamburgerBtnClose"
                  className="btn-link border-0 bg-transparent d-inline-flex align-items-center text-decoration-none"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="pe-2">
                    <CloseIconBlock />
                  </span>
                  <span className="close-text pt-1">{t("close")}</span>
                </button>
              </div>
              <hr className="border-w-2" />
              <div className="p-3">
                <div>
                  <Button
                    data-testid={"submitButton"}
                    variant={
                      "secondary_btn w-100 d-flex gap-2 align-items-center justify-content-center"
                    }
                    onClick={() => {
                      removeUser();
                      signOut();
                    }}
                  ><UserIconBlack /> {t("logout")}</Button>
                </div>

                <div className="dark-gray-color mt-4">
                  <ButtonLink
                    isHeader={true}
                    buttonText="Contact Us"
                    dataTestId="contactUsButton"
                    icon={<MailIcon />}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => {
                      navigate(`/app/${CONTACTUS}`);
                      setIsOpen(false);
                    }}
                  />
                </div>
              </div>
              <hr className="border-w-2" />

              <div className="pb-4">
                <div className="accordion-hamburgermenu">
                  <MenuAccordion
                    setIsOpen={setIsOpen}
                    id="hamburger_menu_mob"
                  />
                </div>
                {<hr className="border-w-2" />}
                <ul className="hamburger-nav-two">
                  {Object.keys(brandConfig.profileMenuLinks).map(
                    (key: string) => {
                      const item = brandConfig.profileMenuLinks[key];
                      return (
                        <li key={key}>
                          <NavLink
                            key={key}
                            to={item.to}
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                            data-testid={item.id}
                          >
                            {t(item.title)}
                          </NavLink>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </ModalOverlayHamburger>
        </div>
      )}
    </>
  );
};
export default HamburgerMenu;
