import { DEFAULT_THEME } from "@sentaraui/optimahealth_web";
import {
  FooterNavigation,
  ProfileMenuLinks,
  TopNavLinks,
  avMedBaseUrl,
  memberBaseUrl,
  memberBrokerContactUs,
} from "./Navigation";
import { FooterNavType, LinkListType } from "utils/model/CommonTypes";
import { DEVICE_MANAGEMENT_IMAGE, LINK } from "./Constants";

export type BrandType = {
  brandName: string;
  hostNames?: Array<string>;
  footerNavigation: { [x: string]: FooterNavType };
  topNavLinks: { [x: string]: LinkListType };
  profileMenuLinks: { [x: string]: any };
  memberBrokerContactUs: string;
  homeLink: string;
  link: { [x: string]: any };
  oidcConfig: { [x: string]: any };
  image: { [x: string]: any };
};

export type BrandsType = {
  [x: string]: BrandType;
};

export const BRAND_CONFIG: BrandsType = {
  AVMED: {
    brandName: "AVMED",
    hostNames: [
      "brokerdev2.sentarahealthplans.com",
      "brokerqa2.sentarahealthplans.com",
      "brokerreg2.sentarahealthplans.com",
      "uat.broker.avmed.com",
      "broker.avmed.com",
      "dev.broker.avmed.com",
      "qa.broker.avmed.com",
      "reg.broker.avmed.com",
    ],
    footerNavigation: {
      ...FooterNavigation,
      ...{
        footerTermsOfUse: {
          ...FooterNavigation.footerTermsOfUse,
          url: avMedBaseUrl + "/terms-of-use/",
        },
        footerLangAssist: {
          ...FooterNavigation.footerLangAssist,
          url: avMedBaseUrl + "/accessibility-statement",
        },
        footerNoticeOfNonDiscrimination: {
          ...FooterNavigation.footerNoticeOfNonDiscrimination,
          url: avMedBaseUrl + "/notice-of-nondiscrimination",
        },
      },
    },
    profileMenuLinks: {
      ...ProfileMenuLinks,
      ...{
        navLinkProfile: {
          ...ProfileMenuLinks.navLinkProfile,
          to: `${avMedBaseUrl}/web/business/agents/`,
        },
      },
    },
    topNavLinks: {
      ...TopNavLinks,
      navTopBrokerHome: {
        ...TopNavLinks.navTopBrokerHome,
        to: `${avMedBaseUrl}/web/business/agents/`,
      },
    },
    memberBrokerContactUs: `${avMedBaseUrl}/contact-us `,
    homeLink: `${avMedBaseUrl}/web/business/agents/`,
    link: {
      privacyPolicy: LINK.PRIVACY_POLICY_AVMED,
      homePageLink: LINK.AGENTHOME_LINK,
      sorc: "mailto:AvMedIFPMAgentSupport@avmed.org",
      sorc1: "mailto:smallgroupsupport@avmed.org",
      dashboardLearnMoreLink: LINK.LEARN_MORE_LINK_DASHBOARD_BRAND,
      supportTileLink: LINK.CONTACT_US_LINK_BRAND,
    },
    oidcConfig: {
      client_id: process.env.REACT_APP_AVMED_BROKER_OIDC_CLIENT_ID,
    },
    image: {
      DuoSentPushImg: DEVICE_MANAGEMENT_IMAGE.image49,
      DuoPushAppleImg: DEVICE_MANAGEMENT_IMAGE.image45,
      DuoPushAndroidImg: DEVICE_MANAGEMENT_IMAGE.image46,
      DuoUserExpAppleImg: DEVICE_MANAGEMENT_IMAGE.image47,
      DuoUserExpAndroidImg: DEVICE_MANAGEMENT_IMAGE.image48,
      DuoStartSetupImg: DEVICE_MANAGEMENT_IMAGE.image50,
    },
  },
  DEFAULT: {
    brandName: "DEFAULT",
    footerNavigation: FooterNavigation,
    topNavLinks: TopNavLinks,
    profileMenuLinks: ProfileMenuLinks,
    memberBrokerContactUs: memberBrokerContactUs,
    homeLink: `${memberBaseUrl}/brokers/`,
    link: {
      privacyPolicy: LINK.PRIVACY_POLICY,
      homePageLink: LINK.BROKERHOME_LINK,
      sorc: "mailto:webmaster@sentara.com",
      dashboardLearnMoreLink: LINK.LEARN_MORE_LINK_DASHBOARD,
      supportTileLink: LINK.CONTACT_US_LINK,
    },
    oidcConfig: {
      client_id: process.env.REACT_APP_SENTARA_BROKER_OIDC_CLIENT_ID,
    },
    image: {
      DuoSentPushImg: DEVICE_MANAGEMENT_IMAGE.image2,
      DuoPushAppleImg: DEVICE_MANAGEMENT_IMAGE.image3,
      DuoPushAndroidImg: DEVICE_MANAGEMENT_IMAGE.image4,
      DuoUserExpAppleImg: DEVICE_MANAGEMENT_IMAGE.image5,
      DuoUserExpAndroidImg: DEVICE_MANAGEMENT_IMAGE.image6,
      DuoStartSetupImg: DEVICE_MANAGEMENT_IMAGE.image13,
    },
  },
};

export type Theme = typeof DEFAULT_THEME;

export const AVMED_THEME: Theme = {
  accordion: {
    background: "#f5f0ee",
    text: "#171717",
  },
  accordionHeader: {
    background: "#333131",
    text: "#fff",
    fontSize: "1.25rem",
    fontWeight: "700",
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
  },
  accordionFAQ: {
    backgroundColor: "#fff",
    iconStroke: "#03646f",
    text: "#03646f",
  },
  alert: {
    danger: {
      background: "rgb(237, 7, 7)",
      border: "rgb(241, 174, 181)",
      icons: "#fff",
      links: "#fff",
      text: "#fff",
      title: "#fff",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    info: {
      background: "rgb(207, 244, 252)",
      border: "rgb(158, 234, 249)",
      icons: "#002F43",
      links: "#002F43",
      text: "rgb(5, 81, 96)",
      title: "#002F43",
    },
    success: {
      background: "rgb(209, 231, 221)",
      border: "rgb(163, 207, 187)",
      icons: "#166534",
      links: "#166534",
      text: "rgb(10, 54, 34)",
      title: "#166534",
    },
    titleFontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    warning: {
      background: "rgb(255, 243, 205)",
      border: "rgb(255, 230, 156)",
      icons: "#92400E",
      links: "#92400E",
      text: "rgb(102, 77, 3)",
      title: "#92400E",
    },
  },

  button: {},
  buttonDropdown: {
    active: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    background: "#0000",
    border: "#eee7e4",
    disabled: {
      background: "#a7c6c2",
      border: "#a7c6c2",
      text: "#fff",
    },
    focused: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#fcfafa",
      border: "#eee7e4",
      text: "#171717",
    },
    text: "#212529",
  },
  buttonLink: {
    background: "#fff",
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    header: {
      background: "#fff",
      text: "#262626",
      icon: "#262626",
    },
    icon: "#03646f",
    text: "#03646f",
    underline: "#03646f",
  },
  buttonPrimary: {
    active: {
      background: "#0a5057",
      border: "#03646f",
      text: "#a9cace",
    },
    background: "#03646f",
    border: "#03646f",
    disabled: {
      background: "#a9cace",
      border: "#a9cace",
      text: "#fff",
    },
    focused: {
      background: "#0a5057",
      border: "0a5057",
      text: "#fff",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#0a5057",
      border: "#0a5057",
      text: "#fff",
    },
    text: "#fff",
  },
  buttonSecondary: {
    active: {
      background: "#fff",
      border: "#50868c",
      // boxShadow: '#a7c6c2'
      text: "#50868c",
    },
    background: "#fff",
    border: "#03646f",
    disabled: {
      background: "#fff",
      border: "#a9cace",
      text: "#a9cace",
    },
    focused: {
      background: "#fff",
      border: "#0a5057",
      text: "#0a5057",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#fff",
      border: "#0a5057",
      text: "#0a5057",
    },
    text: "#03646f",
  },
  buttonTabs: {
    background: "#f5f0ee",
    border: "#f5f0ee",
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    selected: {
      background: "#333131",
      border: "#333131",
      text: "#fff",
    },
    text: "#333131",
  },
  chatBubble: "#333131",
  checkbox: {
    background: "#fff",
    border: "#8d8585",
    fontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    selected: {
      checkbox: "#03646f",
    },
    text: "#171717",
  },
  commonText: {
    disclaimers: {
      content: "#000",
      contentFontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      title: "#000",
      titleFontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    },
    footerDisclaimers: "#333131",
    borderTopPrimary: "#a9edce",
    borderTopSecondary: "#b4c4fc",
    footerDisclaimersFontFamily:
      '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
    headings: {
      h1: "#171717",
      h1FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"Literata_medium", Georgia, Times, serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h2: "#333131",
      h2FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h3: "#333131",
      h3FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h4: "#333131",
      h4FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h5: "#333131",
      h5FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
      h6: "#333131",
      h6FontFamilies: {
        black: '"RedHatDisplay_black", Helvetica, Arial, sans-serif',
        body: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
        bold: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
        extraBold: '"RedHatDisplay_extraBold", Helvetica, Arial, sans-serif',
        light: '"RedHatDisplay_light", Helvetica, Arial, sans-serif',
        medium: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
        regular: '"RedHatDisplay_regular", Helvetica, Arial, sans-serif',
        semiBold: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
      },
    },
    labels: "#333131",
    labelsFontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    links: "#03646f",
    linksFontFamily: '"RedHatDisplay_medium", Helvetica, Arial, sans-serif',
    paragraphs: "#333131",
    paragraphsFontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    disabled: "#696363",
  },
  contactInfo: {
    text: "#03646f",
  },
  dropdownButton: {
    border: "#171717",
    background: "#fff",
    dropdownIcon: "#171717",
    icon: "#171717",
    text: "#171717",
  },
  footerLinks: {
    background: "#f5f0ee",
    fontFamily: '"RedHatDisplay_semibold", Helvetica, Arial, sans-serif',
    text: "#4b4849",
  },
  globalError: {
    backgroundColor: "#fef2f2",
    borderColor: "#fecaca",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#991b1b",
  },
  globalSuccess: {
    backgroundColor: "#F0FDF4",
    borderColor: "#BBF7D0",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#166534",
    successIcon: "#166534",
  },
  icons: {
    accordionDownArrow: "#03646f",
    commission:'#171717',
    passwordHelp:'#171717',
    agent: {
      backgroundCircle: "#CFD9E1",
      background: "#171717",
      foreground: "#fff",
    },
    apple: "#171717",
    billingStatement: "#171717",
    book: "#171717",
    bookOfBusiness: "#171717",
    authorizations: "#171717",
    calendar: "#171717",
    chat: {
      background: "#171717",
      foreground: "#FAFAFA",
    },
    chatbot: {
      background: "#171717",
      foreground: "#fff",
    },
    chatbotClose: "#262626",
    chatBubble: "#262626",
    chatCircleDot: {
      background: "#171717",
      dot: "#22C55E",
      foreground: "#fff",
    },
    chatClose: "#262626",
    chatDot: {
      background: "#171717",
      dot: "#22C55E",
      foreground: "#fff",
    },
    chatInfo: "#262626",
    chatWeb: {
      background: "#E7ECF0",
      foreground: "#262626",
    },
    chatMinimize: "#171717",
    chevronDown: "#333131",
    chevronRight: "#333131",
    chevronUp: "#333131",
    checkBig: "#03646f",
    claims: "#171717",
    clock: {
      background: "#E7ECF0",
      foreground: "#262626",
    },
    close: "#171717",
    closeBlock: "#171717",
    connectingAgent: {
      selectedDot: "#171717",
      unselectedDot: "#A3A3A3",
    },
    customerSupport: "#171717",
    doctor: "#262626",
    document: "#171717",
    documentNote: "#171717",
    employerNew: "#171717",
    enrollmentHistory: "#171717",
    eyeClosed: "#171717",
    eyeOpen: "#171717",
    formsAndDocs: "#171717",
    groupInfo: "#171717",
    hamburger: "#171717",
    healthReimbursement: "#171717",
    healthSavings: "#171717",
    healthSavingsAccount: "#171717",
    help: "#171717",
    idCard: "#171717",
    individualNew: "#171717",
    info: "#333131",
    insurance: "#171717",
    loading: {
      selectedDot: "#171717",
      unselectedDot: "#A3A3A3",
    },
    mail: "#171717",
    medicareEnrollment: "#171717",
    money: "#171717",
    myPlan: "#171717",
    phone: "#212529",
    payMyPremium: "#171717",
    print: "#171717",
    profile: "#171717",
    quoteEnrollManage: "#171717",
    retry: "#171717",
    rightArrow: "#171717",
    search: "#171717",
    iconBlack: "#171717",
    settings: "#171717",
    snapshot: "#171717",
    stethoscope: "#171717",
    support: "#171717",
    taxForm: "#171717",
    umbrella: "#171717",
    urgentCareSearch: "#171717",
    userBlack: "#171717",
    userBlackFull: "#171717",
    userWhite: "#fff",
    virtualVisit: "#171717",
  },
  inlineError: {
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    text: "#dc2626",
  },
  input: {
    background: "#fff",
    border: "#696363",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    icon: "rgb(51, 49, 49)",
    placeholder: "#696363",
    text: "#696363",
  },
  inputError: {
    border: "#ed0707",
  },
  inputFocused: {
    border: "#0467eb",
    boxShadow: "rgba(5, 93, 242, 0.3)",
    outline: "#0467eb",
  },
  link: "#03646f",
  loadingSpinner: "#03646f",
  modalOverlay: {
    closeButton: "#333131",
  },
  navigationMenu: {
    background: "transparent",
    border: "rgb(222, 226, 230)",
    dropdownIcon: "#171717",
    dropdownItems: {
      background: "#fff",
      hovered: {
        background: "rgb(248, 249, 250)",
        text: "rgb(33, 37, 41)",
      },
      text: "#171717",
    },
    text: "#171717",
  },
  planDropdown: {
    dropdownIcon: "#343132",
    text: "#171717",
  },
  profileCard: {
    headings: {
      color: "#333131",
      fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    },
    syllablesCircle: {
      background: "#f5f0ee",
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      text: "#333131",
    },
    text: {
      color: "#333131",
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    },
  },
  circleProgressPrimary: {
    circle: {
      track: "#03646f",
      progress: "#a9edce",
    },
  },
  circleProgressSecondary: {
    circle: {
      track: "#b4c4fc",
      progress: "#3b3ba8",
    },
  },
  radio: {
    background: "#fff",
    border: "#8d8585",
    selected: {
      radio: "#03646f",
    },
  },
  select: {
    background: "#fff",
    border: "#696363",
    dropdown: "#0467eb",
    error: {
      border: "#ed0707",
    },
    focused: {
      border: "#0467eb",
      boxShadow: "rgba(5, 93, 242, 0.3)",
      outline: "#0467eb",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    placeholder: "#696363",
    text: "#696363",
  },
  stepper: {
    activeConnector: "#03646f",
    activeStep: {
      background: "#fff",
      border: "#03646f",
      boxShadow: "#d3e2e0",
      text: "#03646f",
      textFont: "#171717",
    },
    inactiveStep: {
      background: "#fff",
      border: "#d3e2e0",
      text: "#171717",
    },
    inactiveConnector: "#eee7e4",
    selectedStep: {
      background: "#03646f",
      border: "#eee7e4",
      boxShadow: "none",
      text: "#fff",
    },
  },
  submenu: {
    border: "#f5f0ee",
    links: {
      fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
      text: "#171717",
    },
  },
  table: {
    headers: {
      background: "#333131",
      text: "#fff",
    },
    rows: {
      even: {
        background: "#fcfafa",
        text: "#000",
      },
      odd: {
        background: "transparent",
        text: "#000",
      },
    },
  },
  tabs: {
    background: "#fff",
    border: "#fff",
    focused: {
      border: "#e9ecef",
    },
    fontFamily: '"RedHatDisplay_bold", Helvetica, Arial, sans-serif',
    hovered: {
      border: "#f5f0ee",
    },
    selected: {
      background: "#fff",
      border: "#03646f",
      text: "#03646f",
    },
    text: "#171717",
  },
  textarea: {
    background: "#fff",
    border: "#696363",
    dropdown: "#0467eb",
    focused: {
      border: "#0467eb",
      boxShadow: "rgba(5, 93, 242, 0.3)",
      outline: "#0467eb",
    },
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    placeholder: "#696363",
    text: "#696363",
  },
  topNavigation: {
    background: "#fff",
    border: "#fff",
    fontFamily: '"RedHatDisplay_body", Helvetica, Arial, sans-serif',
    hovered: {
      background: "#f5f0ee",
      border: "#b3a9a3",
      text: "#171717",
    },
    text: "#8d8585",
  },
  collapsible: {
    text: "#fff",
    background: "#333131",
  },
};
