import { useEffect, useState } from "react";
import { t } from "i18next";
import { Form } from "../../organisms/form/Form";
import { Headings } from "@sentaraui/optimahealth_web";
import UnpaidGroupBalanceForm from "./UnpaidGroupBalanceForm";
import {
  EmployerGroupResponse,
  EmployerGroupSummaryResponse,
  useEmployerGroup,
  useEmployerGroupSummary,
} from "@sentara/sentara-api-hooks-core";
import { useNavigate, useParams } from "react-router";
import { UNPAID_GROUP_BALANCE } from "utils/constants/Routes";
import { useSearchParams } from "react-router-dom";
import { useGlobalState, useAuth } from "providers";
import { spanDaysDefault } from "utils/constants/Constants";
import UnpaidGroupBalanceTable from "./UnpaidGroupBalanceTable";
import UnpaidGroupBalanceHeader from "./UnpaidGroupBalanceHeader";
import UnpaidGroupBalanceDetails from "./UnpaidGroupBalanceDetails";

const showUnLocate = ({
  isSubmited,
  groupSummary,
  spanDays,
  groupState,
}: {
  isSubmited: boolean;
  groupSummary: Array<EmployerGroupSummaryResponse>;
  spanDays: string;
  groupState: Array<EmployerGroupResponse>;
}) => {
  return !!(
    (!isSubmited && !groupSummary.length && spanDays) ||
    (!isSubmited && groupState && !groupState.length)
  );
};

export const UnpaidGroupBalance = () => {
  const { user } = useAuth();
  const {
    getEmbloyerGroupSummary,
    status: apiStatus,
    state: groupSummary,
  } = useEmployerGroupSummary();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { setPageLoader } = useGlobalState();
  const { groupId } = useParams();
  const {
    state: groupState,
    status: groupApiStatus,
    getEmbloyerGroup,
  } = useEmployerGroup();
  const [isSubmited, setIsSubmited] = useState<boolean>(true);

  const defaultValues = {
    groupNumber: "",
    overduePeriod: "",
  };

  useEffect(() => {
    document.title = t("pageTitleADA.unpaidGroupBalance");
    if (
      isSubmited &&
      (searchParams.get("spanDays") || searchParams.get("groupNumber"))
    ) {
      setPageLoader("unpaidBalance", true);
      if (searchParams.get("groupNumber")) {
        navigate(
          `/app/${UNPAID_GROUP_BALANCE}/${searchParams.get("groupNumber")}`
        );
      } else {
        try {
          getEmbloyerGroupSummary({
            spanDays: searchParams.get("spanDays") ?? spanDaysDefault,
            brokerID: user?.loginID ?? "",
          }).then(() => {
            setIsSubmited(false);
            setPageLoader("unpaidBalance", false);
          });
        } catch {
          navigate(`/app/${UNPAID_GROUP_BALANCE}`);
          setIsSubmited(false);
        }
      }
    }
  }, [searchParams, isSubmited]);

  useEffect(() => {
    if (apiStatus === "success" || groupApiStatus === "success") {
      const ele = document.querySelector(".table-responsive") as HTMLDivElement;
      ele?.scrollIntoView({ behavior: "smooth" });
    }
  }, [apiStatus, groupApiStatus]);

  const onSubmit = (data: any) => {
    setIsSubmited(true);
    const query: { spanDays?: string; groupNumber?: string } = {};
    if (data.overduePeriod) {
      query.spanDays = data.overduePeriod;
    }
    if (data.groupNumber) {
      query.groupNumber = data.groupNumber;
    }
    setSearchParams(query);
  };

  useEffect(() => {
    const callApi = async () => {
      if (groupId && user?.loginID) {
        setPageLoader("unpaidDetails", true);
        try {
          await getEmbloyerGroup({
            brokerID: user.loginID,
            groupID: groupId,
          }).then((res) => {
            setIsSubmited(false);
            setPageLoader("unpaidDetails", false);
            if (!res?.data?.length) {
              navigate(`/app/${UNPAID_GROUP_BALANCE}`);
            }
          });
        } catch {
          setPageLoader("unpaidDetails", false);
        }
      }
    };
    callApi();
  }, [groupId]);

  return groupId && !isSubmited && groupState.length ? (
    <UnpaidGroupBalanceDetails state={groupState} groupId={groupId} />
  ) : (
    <div>
      {searchParams.get("spanDays") && groupSummary.length && !isSubmited ? (
        <div>
          <UnpaidGroupBalanceHeader />
          <UnpaidGroupBalanceTable
            setIsSubmited={setIsSubmited}
            status={apiStatus}
            groupSummary={groupSummary}
            spanDays={searchParams.get("spanDays")}
          />
        </div>
      ) : (
        <div>
          <UnpaidGroupBalanceHeader />
          <Headings
            className="redhat_medium font-twentyfour mb-4"
            dataTestId="txtSearchOptionsSubHeading"
            level={2}
            text={t("unpaidGroupBalance.subTitle.searchOptions")}
          />
          <div
            className="border light-grey-bg border-radius10 p-3 mb-4"
            data-testid="txtSearchInstructions"
          >
            <strong>{t("unpaidGroupBalance.content.instructions")}</strong>:{" "}
            {t("unpaidGroupBalance.content.descText1")}
          </div>

          <Form defaultValues={defaultValues} onSubmit={onSubmit}>
            <UnpaidGroupBalanceForm
              showUnLocate={showUnLocate({
                isSubmited,
                groupSummary,
                spanDays: searchParams.get("spanDays") ?? "",
                groupState,
              })}
            />
          </Form>
        </div>
      )}
    </div>
  );
};

export default UnpaidGroupBalance;
