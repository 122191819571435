import { PrimaryButton, SecondaryButton } from "@sentaraui/optimahealth_web";
import { useTranslation } from "react-i18next";

interface OrderMemberIDCardSubmitProps {
  handleClearForm: Function;
  isFormDirty: boolean
}

const OrderMemberIDCardSubmit = ({
  handleClearForm,
  isFormDirty,
}: OrderMemberIDCardSubmitProps) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-4">
      <PrimaryButton
        data-testid="btnOrderMemberIdCardSearch"
        id="primary"
        type="submit"
        // disabled={!isFormDirty}
        className={
          isFormDirty
            ? "primary_btn btn_block_mob"
            : "primary_btn disabled_btn btn_block_mob"
        }
      >
        {t("viewEligibilityForm.btnSearch")}
      </PrimaryButton>
      <SecondaryButton
        type="button"
        data-testid="btnOrderMemberIdCardClear"
        className={"secondary_btn btn_block_mob"}
        onClick={handleClearForm}
      >
        {t("viewEligibilityForm.btnClear")}
      </SecondaryButton>
    </div>
  );
};
export default OrderMemberIDCardSubmit;
