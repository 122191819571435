import { useEffect, useState } from "react";
import {
  Headings,
  PrimaryButton,
  SecondaryButton,
} from "@sentaraui/optimahealth_web";
import { ORDER_MEMBER_ID_CARD } from "utils/constants/Routes";
import { useNavigate } from "react-router";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import {
  MemberSearchRecord,
  MemberSearchResponse,
  useIdentificationCard,
  useIdentificationCardRequest,
} from "@sentara/sentara-api-hooks-core";
import Pagination from "organisms/pagination/pagination";
import { SERVICE_NUMBER, listPerTable } from "utils/constants/Constants";
import { useAuth, useGlobalState } from "providers";
import {
  DeviceTypes,
  OrderIDList,
  SelectedDepType,
  IdRequestType,
} from "utils/model/CommonTypes";
import SearchTableDeskTop from "./SearchTableDeskTop";
import SearchTableMobile from "./SearchTableMobile";
import { getKey } from "utils/helpers/OrderMemberId";
import { ModalOverlayPopUp } from "organisms/popup/ModalOverlayPopUp";

interface SearchProps {
  memberList: MemberSearchResponse | null;
  isMedicare:boolean;
}

const OrderMemberIDCardSearch = (props: SearchProps) => {
  const { user } = useAuth();
  const { getIdentificationCard } = useIdentificationCard();
  const { getIdentificationCardRequest } = useIdentificationCardRequest();
  const { setPageLoader } = useGlobalState();
  const { memberList,isMedicare } = props;
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [searchFields, setSearchFields] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [pageList, setPageList] = useState<OrderIDList>();
  const [selectedDep, setSelectedDep] = useState<SelectedDepType>();
  const [page, setPage] = useState<number>(1);
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  const cardRequest = async () => {
    let content = "";
    if (!selectedDep || !Object.keys(selectedDep).length) {
      setOpenPopup(true);
      content = t("noMemberSelected");
      setPopupData(content);
      return;
    }
    const _requsted: IdRequestType = {
      success: [],
      pending: [],
      failed: [],
    };
    if (user?.loginID) {
      for (const i in selectedDep) {
        const item: any = selectedDep[i];

        for (const id of item) {
          setPageLoader("getIdentificationCardRequest", true);
          const res = await getIdentificationCardRequest({
            memberId: id,
            loginId: user?.loginID,
          });
          setPageLoader("getIdentificationCardRequest", false);
          if (res?.error && res?.errorCode) {
            if (res?.errorCode.toString() === "409") {
              _requsted.pending.push(id);
            } else {
              _requsted.failed?.push(id);
            }
          } else if (res?.data) {
            _requsted.success.push(id);
          } else {
            _requsted.failed?.push(id);
          }
        }
      }
    }
    let key = getKey(_requsted);
    setOpenPopup(true);
    content = t(`orderMemberIDCard.request.${key}`, {
      memberId: _requsted.success.join(", "),
      memberIdFailed: _requsted.failed.join(", "),
      memberIdPending: _requsted.pending.join(", "),
      serviceNo: SERVICE_NUMBER.BROKER_SERVICE_NUMBER,
      serviceNo1: SERVICE_NUMBER.BROKER_SERVICE_NUMBER_1,
    });
    setPopupData(content);
  };

  const printParamValue = (key: string) => {
    return ["firstName", "lastName"].includes(key)
      ? "'" + searchParams.get(key) + "'"
      : searchParams.get(key);
  };

  useEffect(() => {
    let params: any = [];
    searchParams.forEach((item: string, key: string) => {
      if (!["page", "checkboxAllMembers"].includes(key)) {
        if ("groupId" === key && !item) {
          if (
            !params.length ||
            ["on", "true"].includes(
              searchParams.get("checkboxAllMembers") ?? ""
            )
          ) {
            const groups: Array<string> = [];
            user?.groups?.forEach((element, i) => {
              if (i < 25) {
                groups.push(element.groupID.toString());
              }
            });
            params[0] = `${t("orderMemberIDCard.allMemberGroups")} ${groups.join(", ")}...`;
          }
        } else if (item) {
          if ("groupId" === key) {
            if (
              !params.length ||
              ["on", "true"].includes(
                searchParams.get("checkboxAllMembers") ?? ""
              )
            ) {
              params[0] = `${t("orderMemberIDCard.allMemberGroups")} ${item}`;
            }
          } else {
            if (
              (params[0] as string)?.indexOf(
                t("orderMemberIDCard.allMemberGroups")
              ) > -1 &&
              !["on", "true"].includes(
                searchParams.get("checkboxAllMembers") ?? ""
              )
            ) {
              params = params.slice(1);
            }
            if (key !== "groupId" && key !== "page" && item && key !== "lineOfBusiness") {
              params.push(
                `${key === "lastName"  ? t("viewEligibilityForm.memberWith") : ""} ${t("viewEligibilityForm.key." + key)} ${printParamValue(key)}`
              );
            }
            // params.push(
            //   `${key === "lastName" ? t("viewEligibilityForm.memberWith") : ""} ${t("viewEligibilityForm.key." + key)} ${printParamValue(key)}`
            // );
          }
        }
      }
    });
    setSearchFields(params);
  }, [searchParams]);

  useEffect(() => {
    const list: any = {};
    memberList?.Result?.forEach((item: MemberSearchRecord) => {
      if (item.memberId) {
        const id = `${item.memberId.split("*")[0]}+dep`;
        list[item.memberId] = {
          dateOfBirth: item.dateOfBirth,
          firstName: item.firstName,
          midName: item.midName,
          lastName: item.lastName,
          relationshipCode: item.relationshipCode,
        };
        if (!list[id]) {
          list[id] = [];
        }
        list[id].push(item.memberId);
      }
    });
    Object.keys(list).forEach((key) => {
      if (Array.isArray(list[key]) && list[key].length < 2) {
        delete list[key];
      }
    });
    setPageList(list);
  }, [memberList?.Result]);

  const searchFieldsJoin = (searchFields: string[]) => {
    const last = searchFields.length > 1 ? searchFields.pop() : "";
    return last
      ? `${searchFields.join(", ")} and ${last}`
      : searchFields.join(", ");
  };

  return (
    <div>
      <Headings
        className="redhat_semibold fw-600 font-twentyfour black-color mb-4"
        level={2}
        text={t("orderMemberIDCard.searchResult")}
        dataTestId="txtOrderMemberIdCardSearchResultHeader"
      />
      <div
        className="mt-3 mb-4"
        data-testid="lblOrderMemberIdCardYouSearchedFor"
      >
        <strong>{t("viewEligibilityForm.searchFor")}</strong>{" "}
        {(searchFields[0] as string)?.indexOf(
          t("orderMemberIDCard.allMemberGroups")
        ) > -1
          ? searchFields[0]
          : searchFieldsJoin(searchFields)}
      </div>
      <SecondaryButton
        data-testid="btnOrderMemberIdCardNewSearch"
        className={"secondary_btn btn_block_mob mb-4"}
        onClick={() => navigate(`/app/${ORDER_MEMBER_ID_CARD}`)}
      >
        {t("orderMemberIDCard.newSearch")}
      </SecondaryButton>
      <div className="d-flex flex-column gap-3 p-4 border-radius10 mb-4 light-grey-bg border-1">
        <div>
          <strong>{t("orderMemberIDCard.instructions")}</strong>{" "}
          {t("orderMemberIDCard.instructionsContent")}
        </div>
        <div>
          <strong>{t("orderMemberIDCard.notes")}</strong>{" "}
          {t("orderMemberIDCard.notesContent")}
          {t("orderMemberIDCard.notesContent1")}
          {t("orderMemberIDCard.notesContent2")}
        </div>
      </div>
      <ModalOverlayPopUp
        open={openPopup}
        title={
          selectedDep && Object.keys(selectedDep).length
            ? t("orderMemberIDCard.request.modalTitle")
            : ""
        }
        content={popupData}
        callBack={() => setOpenPopup(false)}
      />

      <div className="row mb-4">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {isMobile ? (
            <SearchTableMobile
              {...{
                pageList,
                selectedDep,
                page,
                setSelectedDep,
                getIdentificationCard,
                isMedicare
              }}
            />
          ) : (
            <SearchTableDeskTop
              {...{
                pageList,
                selectedDep,
                page,
                setSelectedDep,
                getIdentificationCard,
                isMedicare
              }}
            />
          )}
          <div className="d-flex align-items-center pt-2 pb-2 ps-2">
            {pageList && (
              <Pagination
                total={Object.keys(pageList).length ?? 0}
                records={listPerTable}
                current={page ?? 1}
                callBack={(page) => {
                  setPage(page);
                  const ele = document.querySelector(
                    ".table-responsive"
                  ) as HTMLDivElement;
                  ele?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mb-5">
        <PrimaryButton
          data-testid="btnOrderMemberIdCardOrderCard"
          id="primary"
          type="submit"
          className={
            selectedDep && Object.keys(selectedDep).length
              ? "primary_btn btn_block_mob"
              : "primary_btn disabled_btn btn_block_mob"
          }
          onClick={() => {
            cardRequest();
          }}
        >
          {t("orderMemberIDCard.orderCard")}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default OrderMemberIDCardSearch;
