import { Link } from "react-router-dom";
import {
  useBenefitPlan,
  useMember,
  DocumentType,
  BrokerDocumentType,
  BrokerAPIStatus,
} from "@sentara/sentara-api-hooks-core";
import {
  Headings,
  PrimaryButton,
  SecondaryButton,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGlobalState } from "providers";
import { Gender } from "utils/constants/Constants";
import { VIEW_ELIGIBILITY } from "utils/constants/Routes";
import {
  convertToDate,
  dobFormat,
  enrollDateFormat,
  enrollFormat,
} from "utils/helpers/DateFormate";
import { pdfConverter, printDiv } from "utils/helpers/HelperFn";
import { ErrorBy } from "organisms/form";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";
import Accumulations from "./Accumulations";

const ViewEligibilityInfo = () => {
  const { memberId, lob } = useParams();
  const navigate = useNavigate();
  const pdfref = useRef<HTMLDivElement>(null);
  const { getMember, state: eligible, status: apiSatus } = useMember();
  const { setPageLoader } = useGlobalState();
  const { getBenefitPlan } = useBenefitPlan();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<{
    open: boolean;
    content?: string;
    title?: string;
  }>({ open: false, content: "", title: "" });

  useEffect(() => {
    if (memberId) {
      setPageLoader("eligility", true);
      setIsSubmited(true);
      getMember({ memberId: memberId }).then(() => {
        setPageLoader("eligility", false);
        setIsSubmited(false);
      });
    }
  }, [memberId]);

  const openFile = (memberId: string, type: BrokerDocumentType) => {
    setPageLoader("getBenefitPlan", true);
    getBenefitPlan({ memberId, documentType: type }).then((res) => {
      setPageLoader("getBenefitPlan", false);
      if (res?.data?.data) {
        pdfConverter(res?.data?.data, `${memberId}_${type}`);
      } else {
        setOpenInfo({ open: true, content: t("featureUnavailable") });
      }
    });
  };

  const addressTo: Array<string> = [];
  const address = eligible?.Address;
  if (address?.address2) {
    addressTo.push(address.address2);
  }
  if (address?.city) {
    addressTo.push(address.city);
  }
  if (address?.state) {
    addressTo.push(address.state);
  }

  const newSearchBtn = (
    <SecondaryButton
      data-testid="clearButton"
      className={"secondary_btn btn_block_mob"}
      onClick={() => navigate(`/app/${VIEW_ELIGIBILITY}`)}
    >
      {t("viewEligibilityForm.btnNewSearch")}
    </SecondaryButton>
  );

  const meridiem = (_date: string) => {
    if (!_date) {
      return "";
    }
    const d = new Date(_date);
    return d.getHours() > 12 ? "PM" : "AM";
  };

  const formatPhoneNumber = (phoneNumber: any) => {
    // Remove all non-digit characters from the phone number
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // Format the cleaned phone number into the desired format
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    // If the phone number does not match the expected format, return it as is
    return phoneNumber;
  };
  return (
    <div ref={pdfref}>
      <ModalOverlayWithOutClose
        open={openInfo.open}
        callBack={() => setOpenInfo({ open: false })}
        title={openInfo.title}
        content={openInfo.content}
      />
      <Headings
        className="redhat_semibold font-twentyfour black-color mb-3"
        level={2}
        text={t("viewEligibilityForm.memberInfo")}
        dataTestId="txtViewEligibilityMemberInfoTableTitle"
      />
      {apiSatus !== BrokerAPIStatus.INITIATE && eligible ? (
        <>
          <table
            className="table table-custom-style black-color table-column-50 mb-4"
            data-testid="tblMemberInfoTable"
          >
            <tbody>
              <tr>
                <td>
                  <strong>
                    {t("viewEligibilityForm.asOf")} {convertToDate(new Date())}:
                  </strong>
                </td>
                <td>
                  {eligible.Policy.disEnrolledDate &&
                    t("viewEligibilityForm.disenrolledAsOf")}{" "}
                  {enrollFormat(eligible.Policy.disEnrolledDate)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.memberName")}:</strong>
                </td>
                <td>{`${eligible.Name.firstName} ${eligible.Name.middleName} ${eligible.Name.lastName}`}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.address")}:</strong>
                </td>
                <td>
                  <div>{eligible.Address.address1}</div>
                  <div>
                    {addressTo.join(", ")} {eligible.Address.zip}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.phone")}:</strong>
                </td>
                <td>{formatPhoneNumber(eligible.Telecom.mobilePhone)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.memberID")}:</strong>
                </td>
                <td>{eligible.Identifier.memberId}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.dob")}:</strong>
                </td>
                <td>{dobFormat(eligible.DateOfBirth)}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.gender")}:</strong>
                </td>
                <td>{Gender[eligible.gender]}</td>
              </tr>
            </tbody>
          </table>

          <Headings
            className="redhat_semibold font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.planInfo")}
            dataTestId="txtPlanInformationTableTitle"
          />
          <table
            className="table table-custom-style black-color table-column-50 mb-4"
            data-testid="tblViewEligibilityPlanInfoTableGroupDetails"
          >
            <tbody>
              {lob === "commercial" && (
                <tr>
                  <td>
                    <strong>{t("viewEligibilityForm.groupIDNumber")}:</strong>
                  </td>
                  <td>{eligible.Policy.groupId}</td>
                </tr>
              )}
              {lob === "commercial" && (
                <tr>
                  <td>
                    <strong>{t("viewEligibilityForm.groupName")}:</strong>
                  </td>
                  <td>{eligible.Policy.groupName}</td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.plan")}:</strong>
                </td>
                <td>{eligible.Policy.planName}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.planType")}:</strong>
                </td>
                <td>{eligible.Policy.planType}</td>
              </tr>
              {!eligible.Policy.disEnrolledDate && (
                <tr>
                  <td>
                    <strong>{t("viewEligibilityForm.enrolledDate")}:</strong>
                  </td>
                  <td>{enrollDateFormat(eligible.Policy.enrolledDate)}</td>
                </tr>
              )}
              {eligible.Policy.disEnrolledDate && (
                <tr>
                  <td>
                    <strong>{t("viewEligibilityForm.disEnrolledDate")}:</strong>
                  </td>
                  <td>{enrollDateFormat(eligible.Policy.disEnrolledDate)}</td>
                </tr>
              )}
              {eligible.Policy.disEnrolledDate && (
                <tr>
                  <td>
                    <strong style={{ whiteSpace: "pre-line" }}>
                      {t("viewEligibilityForm.disEnrolledHistory")}:
                    </strong>
                  </td>
                  <td>
                    <p>
                      <strong style={{ whiteSpace: "pre-line" }}>
                        {t("viewEligibilityForm.disEnrolledHistory")}:{" "}
                      </strong>{" "}
                      {eligible.Policy.groupId}
                    </p>
                    <p className="mb-0">
                      <strong style={{ whiteSpace: "pre-line" }}>
                        {t("viewEligibilityForm.coverageDate")}:{" "}
                      </strong>
                      {convertToDate(eligible.Policy.coverageStartDate)} -{" "}
                      {convertToDate(eligible.Policy.coverageEndDate)}
                    </p>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <strong>
                    {t("viewEligibilityForm.cordinationOfBenefits")}:
                  </strong>
                </td>
                <td>
                  <div>
                    {t("viewEligibilityForm.primaryPay")}{" "}
                    {eligible.Policy.primaryCoordinationOfBenefits || "N/A"}
                  </div>
                  <div>
                    {t("viewEligibilityForm.secondaryPay")}{" "}
                    {eligible.Policy.secondaryCoordinationOfBenefits || "N/A"}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.pcp")}:</strong>
                </td>
                <td>{eligible.Policy.primaryCarePhysicianName}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.pcpEffectiveDate")}:</strong>
                </td>
                <td>{eligible.Policy.primaryCarePhysicianNameEffectiveDate}</td>
              </tr>
            </tbody>
          </table>

          <Headings
            className="redhat_semibold font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.hpd")}
            dataTestId="txtHealthPlanDocsTitle"
          />
          <table className="table table-custom-style black-color table-column-50 mb-4">
            <tbody>
              <tr>
                <td data-testid="txtBenifitsSummaryLabel">
                  <strong>{t("viewEligibilityForm.benefitSummary")}:</strong>
                </td>
                <td>
                  <PrimaryButton
                    data-testid="btnViewBenifitsSummary"
                    className={"primary_btn btn-medium btn_block_mob"}
                    onClick={() => {
                      if (eligible.Identifier.memberId) {
                        openFile(
                          eligible.Identifier.memberId,
                          BrokerDocumentType.BENEFIT_SUMMARY
                        );
                      }
                    }}
                  >
                    {t("viewEligibilityForm.view")}
                  </PrimaryButton>
                </td>
              </tr>
              <tr>
                <td data-testid="txtUniformSummaryLabel">
                  <strong>{t("viewEligibilityForm.summaryBenefits")}:</strong>
                </td>
                <td>
                  <Link
                    to={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (eligible.Identifier.memberId) {
                        openFile(
                          eligible.Identifier.memberId,
                          BrokerDocumentType.SUMMARY_OF_BENEFIT_COVERAGE
                        );
                      }
                    }}
                    className="text-decoration-underline"
                    data-testid="txtUniformSummaryEffectiveDate"
                  >
                    {t("viewEligibilityForm.effective")}
                    {":"} {enrollDateFormat(eligible.Policy.effectiveDate)}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>

          <Headings
            className="redhat_semibold font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.copay&Coinsurance")}
            dataTestId="txtMemberCopayTitle"
          />

          <table
            className="table table-custom-style black-color table-column-50 mb-4"
            data-testid="tblMemberCopayDetails"
          >
            <tbody>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.pcpCaps")}:</strong>
                </td>
                <td>{eligible.WalletCard.pcpCoPay}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.preventative")}:</strong>
                </td>
                <td>{t("viewEligibilityForm.referPreventAmt")}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.specialist")}:</strong>
                </td>
                <td>{eligible.WalletCard.specialistCoPay}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.ucc")}:</strong>
                </td>
                <td>{eligible.WalletCard.uccCoins}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.er")}:</strong>
                </td>
                <td>{eligible.WalletCard.erCoIns}</td>
              </tr>
              <tr>
                <td>
                  <strong>{t("viewEligibilityForm.rx")}:</strong>
                </td>
                <td>{t("viewEligibilityForm.referCoverageDoc")}</td>
              </tr>
            </tbody>
          </table>
          <Accumulations accumulations={eligible?.Accumulations} />
          <div className="mb-4 p-4 border border-radius10 light-grey-bg">
            <strong>{t("viewEligibilityForm.notes")}</strong>{" "}
            {t("viewEligibilityForm.notesContent")}
          </div>
          <hr className="my-4" />
          <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-5 not-in-print">
            <PrimaryButton
              data-testid="printButton"
              onClick={() => {
                printDiv(pdfref);
                return false;
              }}
              className="primary_btn btn_block_mob"
            >
              {t("viewEligibilityForm.printBtn")}
            </PrimaryButton>
            {newSearchBtn}
          </div>
        </>
      ) : (
        <>
          {!isSubmited ? (
            <>
              <ErrorBy message={t("noResult")} isShow={true} />
              <div className="pt-3">{newSearchBtn}</div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
export default ViewEligibilityInfo;
