import { useAuth,useBranding } from "providers";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LinkListType } from "utils/model/CommonTypes";

type Props = {
  setIsOpen?: (v: boolean) => void;
  list: Array<LinkListType>;
  setInitialActiveIndex: (v: number) => void;
  no: number;
};

const MenuLinks = (props: Props) => {
  const { setIsOpen = () => {} } = props;
  const { t } = useTranslation();
  const { isAccess } = useAuth();
  const { brandConfig } = useBranding();
  const { list, setInitialActiveIndex, no } = props;
  const getToLink = (item: LinkListType) => {

    if (item.id === "lnkChangePassword") {
      return item.url
    }
    if (brandConfig.brandName === "AVMED" && item.id === "lnkCommissionInformation") {
      return item.avmedTo
    }
    return item.to;
  }
  const getTarget = (item: LinkListType) => {
    if (brandConfig.brandName === "AVMED" && item.id === "lnkCommissionInformation") {
      return item.avmedTarget;
    }
    return item.target;
  }

  return (
    <ul className="list-group link-list">
      {list.map((item: LinkListType) =>
        isAccess(item.to) ? (
          <li key={item.id} className="list-group-item list-group-item-action">
            <NavLink
              onClick={() => setIsOpen(false)}
              className={({ isActive }) => {
                if (isActive) {
                  setInitialActiveIndex(no);
                }
                return isActive ? "active-link-list" : "";
              }}
              to={getToLink(item)||""}
              data-testid={item.id}
              aria-label={`${t(item.title)} ${t(item.ariaLabel)}`}
              target={getTarget(item)}
            >
              {t(item.title)}
            </NavLink>
          </li>
        ) : null
      )}
    </ul>
  );
};

export default MenuLinks;
