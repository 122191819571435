import { t } from "i18next";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { useBrokerBookOfBusiness } from "@sentara/sentara-api-hooks-core";
import { useEffect } from "react";

export const BrokerBookOfBusiness = () => {
  const { state, getBrokerBookOfBusiness } = useBrokerBookOfBusiness();
  useEffect(() => {
    document.title = t('pageTitleADA.brokerBookBusinessReporting');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    await getBrokerBookOfBusiness(); // FUTURE update as per the logined in user
  };

  return (
    <>
      <SSONavigationContent
        header={t("sso.header.BrokerBookOfBusiness")}
        ssoUrl={state?.Result?.ssoUrl}
        dataTestId="ssoLinkBrokerBookOfBusinessReporting"
        targetName="BrokerBookOfBusiness"
      />
    </>
  );
};

export default BrokerBookOfBusiness;
