import { t } from "i18next";
import { Brands, useBranding } from "providers";

export const DeviceManagementSupportInfo = () => {
  const { brandConfig } = useBranding();
  return (
    <div className="d-flex flex-column gap-3">
      {brandConfig.brandName === Brands.AVMED ? (
        <div className="d-flex flex-column gap-3">
          <div>
            <strong>{t("deviceManagement.agentServices")}</strong>
          </div>
          <div>
            <div>
              <strong>{t("deviceManagement.groupBusiness")}</strong>
            </div>
            <div>{t("deviceManagement.groupBusinessTollNumber")}</div>
          </div>
          <div>
            <div>
              <strong>
                {t("deviceManagement.individualAndMedicareBusiness")}
              </strong>
            </div>
            <div>
              {t("deviceManagement.individualAndMedicareBusinessTollNumber")}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column gap-3">
          <div>
            <div>
              <strong>{t("deviceManagement.providerRelations")}</strong>
            </div>
            <div>{t("deviceManagement.providerRelationsNumber")}</div>
            <div>{t("deviceManagement.providerRelationsTollNumber")}</div>
          </div>
          <div>
            <div>
              <strong>{t("deviceManagement.brokerServices")}</strong>
            </div>
            <div>{t("deviceManagement.brokerServicesNumber")}</div>
            <div>{t("deviceManagement.brokerServicesTollNumber")}</div>
          </div>
        </div>
      )}
      <div>
        <div>
          <strong>{t("deviceManagement.employerGroupSupport")}</strong>
        </div>
      </div>
    </div>
  );
};
export default DeviceManagementSupportInfo;
