import { Route, Routes } from "react-router-dom";
import { AppLayout } from "../../organisms/layouts";
import {
  COMMISSION_INFORMATION,
  COMMISSION_STATEMENT,
  CONTACTUS,
  CONTACTUS_SUCCESS,
  EMPLOYER_BENEFITS_ENROLLMENT,
  GROUP_REPORTING,
  ORDER_MEMBER_ID_CARD,
  UNPAID_GROUP_BALANCE,
  VIEW_ELIGIBILITY,
  EMPLOYER_NEW_RENEWING,
  BROKER_BOOK_BUSINESS_REPORTING,
  MEDICARE_ENROLLMENT,
  MEDICARE_ENROLLMENT_2025,
  MEDICARE_BOOK_BUSINESS,
  COMMISSION_ACTIVITY,
  BROKER_REWARDS_PROGRAM,
  INDIVIDUAL_NEW_RENEWING,
  MEDICARE_COMMISSION,
} from "utils/constants/Routes";
import OrderMemberIDCard from "pages/orderMemberIDCard/OrderMemberIDCard";
import ViewEligibility from "pages/quotEnrollManagement/ViewEligibility";
import { CommissionPolicy } from "pages/commissionPolicy/CommissionPolicy";
import EmployerBenefitsEnrollment from "pages/employerBenefitsEnrollment/EmployerBenefitsEnrollment";
import UnpaidGroupBalance from "pages/unpaidGroupBalance/UnpaidGroupBalance";
import CommissionStatement from "pages/commissionStatement/CommissionStatement";
import EmployerNewRenewing from "pages/employerNewRenewing/EmployerNewRenewing";
import EmployerGroupReporting from "pages/employerGroupReporting/EmployerGroupReporting";
import BrokerBookOfBusiness from "pages/brokerBookOfBusiness/BrokerBookOfBusiness";
import MedicareEnrollment from "pages/medicareEnrollment/MedicareEnrollment";
import MedicareBookOfBusiness from "pages/medicareBookOfBusiness/MedicareBookOfBusiness";
import CommissionActivity from "pages/commissionActivity/CommissionActivity";
import BrokerRewardsProgram from "pages/brokerRewardsProgram/BrokerRewardsProgram";
import ContactUsPage from "pages/contactUs/ContactUsPage";
import ContactUsSuccess from "pages/contactUs/ContactUsSuccess";
import IndividualNewRenewing from "pages/individualNewRenewing/IndividualNewRenewing";
import ViewEligibilityInfo from "pages/quotEnrollManagement/ViewEligibilityInfo";
import Dashboard from "pages/dashboard/Dashboard";
import MedicareCommission from "pages/medicareCommission/MedicareCommission";
import MedicareAEP from "pages/medicareAEP/MedicareAEP";

export const AppRoute = () => {
  return (
    <Routes>
      <Route path={"/"} element={<AppLayout />}>
        <Route path={VIEW_ELIGIBILITY} element={<ViewEligibility />} />
        <Route path={`${VIEW_ELIGIBILITY}/:memberId/:lob`} element={<ViewEligibilityInfo />} />
        <Route path={EMPLOYER_BENEFITS_ENROLLMENT} element={<EmployerBenefitsEnrollment />} />
        <Route path={ORDER_MEMBER_ID_CARD} element={<OrderMemberIDCard />} />
        <Route path={COMMISSION_INFORMATION} element={<CommissionPolicy />} />
        <Route path={EMPLOYER_NEW_RENEWING} element={<EmployerNewRenewing />} />
        <Route path={UNPAID_GROUP_BALANCE} element={<UnpaidGroupBalance />} />
        <Route path={`${UNPAID_GROUP_BALANCE}/:groupId`} element={<UnpaidGroupBalance />} />
        <Route path={COMMISSION_STATEMENT} element={<CommissionStatement />} />
        <Route path={GROUP_REPORTING} element={<EmployerGroupReporting />} />
        <Route path={INDIVIDUAL_NEW_RENEWING} element={<IndividualNewRenewing />} />
        <Route path={BROKER_BOOK_BUSINESS_REPORTING} element={<BrokerBookOfBusiness />} />
        <Route path={MEDICARE_ENROLLMENT} element={<MedicareEnrollment />} />
        <Route path={MEDICARE_ENROLLMENT_2025} element={<MedicareAEP />} />
        <Route path={MEDICARE_BOOK_BUSINESS} element={<MedicareBookOfBusiness />} />
        <Route path={MEDICARE_COMMISSION} element={<MedicareCommission />} />
        <Route path={COMMISSION_ACTIVITY} element={<CommissionActivity />} />
        <Route path={BROKER_REWARDS_PROGRAM} element={<BrokerRewardsProgram />} />
        <Route path={CONTACTUS} element={<ContactUsPage />} />
        <Route path={CONTACTUS_SUCCESS} element={<ContactUsSuccess />} />
        <Route path="*" element={<Dashboard />}></Route>
      </Route>
    </Routes>
  );
};
