import SSONavigationContent from "pages/sso/SSONavigationContent";
import { t } from "i18next";

import { useEffect } from "react";
import { useGroupReport } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";

const EmployerGroupReporting = () => {
  const { user } = useAuth();

  const { state: groupReport, getGroupReport } = useGroupReport();
  useEffect(() => {
    document.title = t('pageTitleADA.groupReporting');
    fetchSSO();
  }, []);
  const fetchSSO = async () => {
    if (user?.loginID && user?.person?.personID) {
      await getGroupReport({
        loginID: user.loginID,
        personID: user?.person?.personID as string,
      });
    }
  };
  return (
    <>
      <SSONavigationContent
        header={t("sso.header.groupReporting")}
        ssoUrl={groupReport?.Result?.ssoUrl}
        dataTestId="ssoLinkGroupReporting"
        targetName="EmployerGroupReporting"
      />
    </>
  );
};
export default EmployerGroupReporting;
