import { useEffect, useState } from "react";
import { t } from "i18next";
import { useAuth, useGlobalState } from "providers";
import { useDeviceManagement } from "@sentara/sentara-api-hooks-core";
import DuoComponent from "pages/deviceManagementPortal/deviceManagementForm/DuoComponent";
import { GlobalApiError } from "organisms/form";

export const DeviceManagement = () => {
  const { setDmTitle } = useGlobalState();
  useEffect(() => {
    document.title = t('pageTitleADA.deviceManagement');
    setDmTitle(t("deviceManagement.deviceManagementHeading"));
  }, [setDmTitle]);

  const {
    getDeviceManagement,
    state,
    error: loginError,
  } = useDeviceManagement();
  const { user } = useAuth();
  const [duoVisible, setDuoVisible] = useState(false);

  useEffect(() => {
    if (user) {
      deviceManagementSSO(user?.loginID);
    }
  }, [user]);

  useEffect(() => {
    if (state?.Result) {
      setDuoVisible(true);
    }
  }, [state]);
  const deviceManagementSSO = async (loginid: string) => {
    await getDeviceManagement({ loginID: loginid });
  };

  return (
    <div>
      <div>
        <div className="mb-4">{t("deviceManagement.deviceManagementDesc")}</div>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="d-flex flex-column p-4  mb-4 ">
              <GlobalApiError apiError={loginError} />
              {duoVisible && (
                <DuoComponent
                  host={state?.Result.host}
                  signInRequest={state?.Result.signRequest}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeviceManagement;
