import { useMedicareBookOfBusiness } from "@sentara/sentara-api-hooks-core";
import { useAuth } from "providers";
import SSONavigationContent from "pages/sso/SSONavigationContent";
import { useEffect } from "react";
import { t } from "i18next";

const MedicareBookOfBusiness = () => {
  const { user } = useAuth();
  const { state, getMedicareBookOfBusiness } = useMedicareBookOfBusiness();

  useEffect(() => {
    document.title = t('pageTitleADA.medicareBookBusiness');
    fetchSSO();
  }, []);

  const fetchSSO = async () => {
    if (user?.loginID && user?.person?.personID) {
      await getMedicareBookOfBusiness({
        userID: user.loginID,
        personID: user?.person?.personID as string,
      });
    }
  };

  return (
    <SSONavigationContent
      header={t("sso.header.medicareBookOfBusiness")}
      ssoUrl={state?.Result.ssoUrl}
      dataTestId="ssoLinkMedicareBookOfBusiness"
      targetName="MedicareBookOfBusiness"
    />
  );
};

export default MedicareBookOfBusiness;
