import React, { useEffect } from "react";
import { t } from "i18next";
import { Image, Headings, PrimaryButton } from "@sentaraui/optimahealth_web";
import HomeTiles from "./HomeTiles";
import bannerImagePath from "../../assets/images/dashboard/banner_img_mob.png";
import { Brands, useBranding } from "providers";

export const Dashboard = () => {
    const { brandConfig } = useBranding();

    useEffect(() => {
        document.title = t("pageTitleADA.dashboard");
    }, []);

    return (
        <div>
            <div className="dashboard">
                <div className="hero-section d-flex">
                    <div className="hero-img">
                        <div className="hero-container d-flex flex-column">
                            <div className="inner-cont d-flex flex-column">
                                <div className="d-md-none d-lg-none" aria-hidden="true">
                                    <Image
                                        dataTestId={"imgEmployerDashboard"}
                                        path={bannerImagePath}
                                        alttext={t("dashboard.label.bannerImgAltText")}
                                        className="img-fluid w-100 border-radius10"
                                    />
                                </div>
                                <Headings
                                    className="literata_medium mb-0"
                                    level={2}
                                    text={t('dashboard.label.title')}
                                />
                                <p className="mb-0">{t('dashboard.label.bannerDesc')}</p>
                                {Brands.AVMED !== brandConfig.brandName && (
                                    <PrimaryButton
                                        data-testid={'learnMoreButton'}
                                        className={'primary_btn mw-auto btn_block_mob'}
                                        aria-label={`${brandConfig.link.dashboardLearnMoreLink} ${t("nav.menu.ssoAriaLabel")}`}
                                        onClick={() => window.open(brandConfig.link.dashboardLearnMoreLink, '_blank')}
                                    >
                                        {t('dashboard.label.learnMore')}
                                    </PrimaryButton>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-cont d-flex">
                <div
                    className="left-border-white"
                    aria-hidden="true"
                ></div>

                <HomeTiles />
                <div
                    className="bottom-border-white"
                    aria-hidden="true"
                ></div>
            </div>
        </div >
    )
}

export default Dashboard;