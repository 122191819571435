import {
  SecondaryButton,
  Link,
  AccordionTable,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import {
  BrokerAPIStatus,
  EmployerGroupSummaryResponse,
} from "@sentara/sentara-api-hooks-core";
import { convertToDate } from "utils/helpers/DateFormate";
import { useNavigate } from "react-router";
import { UNPAID_GROUP_BALANCE } from "utils/constants/Routes";
import { amountFormat } from "utils/helpers/HelperFn";
import { useGlobalState } from "providers";
import { DeviceTypes } from "utils/model/CommonTypes";
import { GroupStatus } from "utils/constants/Constants";

interface UnpaidGroupBalanceTableProps {
  groupSummary: Array<EmployerGroupSummaryResponse>;
  spanDays?: string | null;
  status: string;
  setIsSubmited: (v: boolean) => void;
}

export const UnpaidBalanceNotes = () => {
  return (
    <div className="d-flex flex-column gap-3" data-testid="unpaidnotes">
      <div>
        <strong data-testid="txtNotesLabel">
          {t("unpaidGroupBalance.content.notes")}:
        </strong>{" "}
        <span data-testid="txtNotesInDetail">
          {t("unpaidGroupBalance.content.descText3")}
        </span>
      </div>
      <div>{t("unpaidGroupBalance.content.descText4")}</div>
    </div>
  );
};
const UnpaidGroupBalanceTable = ({
  groupSummary,
  spanDays,
  status,
  setIsSubmited,
}: UnpaidGroupBalanceTableProps) => {
  const navigate = useNavigate();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;

  return (
    <div>
      <div className="border light-grey-bg border-radius10 p-3 mb-4">
        <strong data-testid="txtYouSearchedFor">
          {t("unpaidGroupBalance.content.youSearchedFor")}:
        </strong>{" "}
        {spanDays && (
          <span data-testid="txtYouSearchedForDescription">
            {t("unpaidGroupBalance.content.searchForSpandays")}
            {spanDays} {t("unpaidGroupBalance.content.days")}
          </span>
        )}
      </div>

      <div className="mb-4">
        <SecondaryButton
          data-testid="btnPerformNewSearch"
          className={"secondary_btn btn_block_mob"}
          onClick={() => navigate(`/app/${UNPAID_GROUP_BALANCE}`)}
        >
          {t("button.label.performANewSearch")}
        </SecondaryButton>
      </div>

      <div className="border light-grey-bg border-radius10 p-3 mb-4">
        <div className="pb-2">
          <strong data-testid="txtInstructionsLabel">
            {t("unpaidGroupBalance.content.instructions")}:
          </strong>{" "}
          <span data-testid="txtDetailedInstructions">
            {t("unpaidGroupBalance.content.descText2")}
          </span>
        </div>
        <UnpaidBalanceNotes />
      </div>
      {!isMobile && (
        <div className="table-responsive mb-4">
          <table className="table-custom-style" data-testid="tblGroupDetails">
            <thead>
              <tr>
                <th scope="col">
                  {t("unpaidGroupBalance.content.groupNumber")}
                </th>
                <th scope="col">{t("unpaidGroupBalance.content.groupName")}</th>
                <th scope="col">{t("unpaidGroupBalance.content.totalDue")}</th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.oldestInvoice")}
                </th>
                <th scope="col">
                  {t("unpaidGroupBalance.content.groupStatus")}
                </th>
              </tr>
            </thead>
            <tbody>
              {groupSummary.length ? (
                groupSummary.map((item, index) => {
                  return (
                    <tr key={`${item.GroupID}`}>
                      <td scope="row">
                        <Link
                          data-testid={`viewButton${index}`}
                          onClick={() => setIsSubmited(true)}
                          to={`/app/${UNPAID_GROUP_BALANCE}/${item.GroupID}`}
                          className="darkblue-color bg-transparent border-0 text-decoration-underline p-0"
                        >
                          {item.GroupID}
                        </Link>
                      </td>
                      <td>{item.GroupName}</td>
                      <td>{amountFormat(item.AmountDue)}</td>
                      <td>{convertToDate(item.InvoiceDate)}</td>
                      <td>{t(GroupStatus[item.GroupStatus])}</td>
                    </tr>
                  );
                })
              ) : (
                <>
                  {status !== BrokerAPIStatus.PROCESSING && (
                    <tr className="text-center">
                      <td colSpan={5}>{t("noResult")}</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}

      {isMobile &&
        (groupSummary.length > 0 ? (
          <div className="mb-4">
            <AccordionTable
              initialActiveIndex={0}
              id="unpaid_group_balance_search_table"
              items={
                groupSummary.length > 0 &&
                groupSummary.map((item, index) => ({
                  title: (
                    <div>
                      <div>{t("unpaidGroupBalance.content.groupNumber")}</div>
                      <div>
                        <Link
                          onClick={() => setIsSubmited(true)}
                          data-testid={`viewButton${index}`}
                          to={`/app/${UNPAID_GROUP_BALANCE}/${item.GroupID}`}
                        >
                          {item.GroupID}
                        </Link>
                      </div>
                    </div>
                  ),
                  id: "unpaid_group_balance_search_table_data",
                  content: (
                    <>
                      <div className="mb-3">
                        <div>{t("unpaidGroupBalance.content.groupName")}</div>
                        <div>{item.GroupName}</div>
                      </div>
                      <div className="mb-3">
                        <div>{t("unpaidGroupBalance.content.totalDue")}</div>
                        <div>{amountFormat(item.AmountDue)}</div>
                      </div>
                      <div className="mb-3">
                        <div>
                          {t("unpaidGroupBalance.content.oldestInvoice")}
                        </div>
                        <div>{convertToDate(item.InvoiceDate)}</div>
                      </div>
                      <div className="mb-3">
                        <div>{t("unpaidGroupBalance.content.groupStatus")}</div>
                        <div>{t(GroupStatus[item.GroupStatus])}</div>
                      </div>
                    </>
                  ),
                }))
              }
            />
          </div>
        ) : (
          <>
            {status !== BrokerAPIStatus.PROCESSING && (
              <div className="mb-4">
                <div className="text-center">{t("noResult")}</div>
              </div>
            )}
          </>
        ))}
    </div>
  );
};

export default UnpaidGroupBalanceTable;
