import { useEffect } from "react";
import { t } from "i18next";
import { Image } from "@sentaraui/optimahealth_web";
import { DEVICE_MANAGEMENT_IMAGE } from "utils/constants/Constants";
import ScrollToTop from "organisms/layouts/ScrollTop";
import { useBranding, useGlobalState } from "providers";
const WithInDuoMobile = () => {
    const WithInDuoMobileContent1 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent17')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent18')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent19')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent20')}</strong>{" "}
        </div>
    );
    const WithInDuoMobileContent2 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent21')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent18')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent19')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent22')}</strong>{" "}
        </div>
    );
    const WithInDuoMobileList = [
        { WithInDuoMobileContent: WithInDuoMobileContent1, WithInDuoMobileImagePath: DEVICE_MANAGEMENT_IMAGE.image19, altText: t('deviceManagement.enrollment.signInImg') },
        { WithInDuoMobileContent: WithInDuoMobileContent2, WithInDuoMobileImagePath: DEVICE_MANAGEMENT_IMAGE.image28, altText: t('deviceManagement.enrollment.startSetUpImg') },
    ];
    return (<>
        <div>{t('deviceManagement.enrollment.smartPhoneContent16')}:</div>
        <ol className="list-alpha d-flex flex-column gap-3">
            {WithInDuoMobileList.map((item, index) => (
                <li key={index}>
                    <div className="pb-1">{item.WithInDuoMobileContent}</div>
                    {item.WithInDuoMobileImagePath &&
                        <Image
                            dataTestId={`imgWithInDuoMobile_${index}`}
                            path={item.WithInDuoMobileImagePath}
                            alttext={item.altText}
                            className="img-fluid"
                        />
                    }
                </li>
            ))}
        </ol>
    </>
    )
}

const AutoPush = () => {
    const AutoPushContent1 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent33')}
        </div>
    );
    const AutoPushContent2 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent34')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent35')}</strong>,{" "}
            {t('deviceManagement.enrollment.smartPhoneContent36')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent37')}</strong>.
        </div>
    );
    const AutoPushContent3 = (
        <div>
            {t('deviceManagement.overview.content12')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent38')}</strong>
        </div>
    );
    const AutoPushContent4 = (
        <div>
            {t('deviceManagement.overview.content12')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent39')}</strong>.
        </div>
    );
    const AutoPushList = [
        { AutoPushContent: AutoPushContent1, AutoPushImagePath: '', altText: '' },
        { AutoPushContent: AutoPushContent2, AutoPushImagePath: '', altText: '' },
        { AutoPushContent: AutoPushContent3, AutoPushImagePath: DEVICE_MANAGEMENT_IMAGE.image22, altText: t('deviceManagement.enrollment.startSetUpImg') },
        { AutoPushContent: AutoPushContent4, AutoPushImagePath: DEVICE_MANAGEMENT_IMAGE.image23, altText: t('deviceManagement.enrollment.startSetUpImg') },
    ];
    return (<div className="d-flex flex-column gap-3">
        <div>
            <strong>{t('deviceManagement.enrollment.smartPhoneContent30')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent31')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent32')}</strong>.
        </div>
        <div className="text-danger">
            <span className="text-decoration-underline">{t('deviceManagement.faq.question10Answer2')}</span>{" "}
            {t('deviceManagement.faq.question10Answer3')}
        </div>
        <ol className="list-alpha d-flex flex-column gap-3">
            {AutoPushList.map((item, index) => (
                <li key={index}>
                    <div className="pb-1">{item.AutoPushContent}</div>
                    {item.AutoPushImagePath &&
                        <Image
                            dataTestId={`imgAutoPush_${index}`}
                            path={item.AutoPushImagePath}
                            alttext={item.altText}
                            className="img-fluid"
                        />
                    }
                </li>
            ))}
        </ol>
    </div>
    )
}

export const EnrollSmartPhone = () => {
    const { setDmTitle } = useGlobalState();
    const { brandConfig } = useBranding();
    useEffect(() => {
        setDmTitle(t('deviceManagement.enrollment.enrollSmartPhoneHeading'));
    }, [setDmTitle]);

    const EnrollPhoneContent1 = (
        <div>
            <strong>{t('deviceManagement.enrollment.smartPhoneContent2')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent3')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent4')}</strong>.{" "}
        </div>
    );
    const EnrollPhoneContent2 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent5')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent4')}</strong>.{" "}
        </div>
    );
    const EnrollPhoneContent3 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent6')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent7')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent8')}
        </div>
    );
    const EnrollPhoneContent4 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent9')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent10')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent11')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent12')}</strong>
        </div>
    );
    const EnrollPhoneContent5 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent13')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent14')}</strong>.{" "}
            {t('deviceManagement.enrollment.smartPhoneContent15')}
        </div>
    );
    const EnrollPhoneContent6 = (
        <WithInDuoMobile />
    );
    const EnrollPhoneContent7 = (
        <div>
            <strong>{t('deviceManagement.enrollment.smartPhoneContent23')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent24')}
        </div>
    );
    const EnrollPhoneContent8 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent25')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent26')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent27')}
        </div>
    );
    const EnrollPhoneContent9 = (
        <div>
            <strong>{t('deviceManagement.enrollment.smartPhoneContent28')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent29')}
        </div>
    );
    const EnrollPhoneContent10 = (
        <AutoPush />
    );
    const EnrollPhoneContent11 = (
        <div>
            <span className="redhat_italic">{t('deviceManagement.enrollment.smartPhoneContent40')}</span>,{" "}
            {t('deviceManagement.enrollment.smartPhoneContent41')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent39')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent42')}
        </div>
    );
    const EnrollPhoneContent12 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent43')}
        </div>
    );
    const EnrollPhoneContent13 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent44')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent45')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent46')}
        </div>
    );
    const EnrollPhoneContent14 = (
        <div>
            {t('deviceManagement.enrollment.smartPhoneContent47')}{" "}
            <strong>{t('deviceManagement.enrollment.smartPhoneContent48')}</strong>{" "}
            {t('deviceManagement.enrollment.smartPhoneContent49')}
        </div>
    );
    const EnrollSmartPhoneList = [
        { enrollPhoneContent: EnrollPhoneContent1, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image15, altText: t('deviceManagement.enrollment.signInImg') },
        { enrollPhoneContent: EnrollPhoneContent2, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image16, altText: t('deviceManagement.enrollment.startSetUpImg') },
        { enrollPhoneContent: EnrollPhoneContent3, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image17, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent4, enrollPhoneImagePath: '', altText: '' },
        { enrollPhoneContent: EnrollPhoneContent5, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image18, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent6, enrollPhoneImagePath: '', altText: '' },
        { enrollPhoneContent: EnrollPhoneContent7, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image20, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent8, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image21, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent9, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image29, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent10, enrollPhoneImagePath: '', altText: '' },
        { enrollPhoneContent: EnrollPhoneContent11, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image24, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent12, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image25, altText: t('deviceManagement.enrollment.typeOfDeviceImg') },
        { enrollPhoneContent: EnrollPhoneContent13, enrollPhoneImagePath: DEVICE_MANAGEMENT_IMAGE.image1, altText: t('deviceManagement.overview.SendMePushImg') },
        { enrollPhoneContent: EnrollPhoneContent14, enrollPhoneImagePath: brandConfig.image.DuoSentPushImg, altText: t('deviceManagement.overview.SentPushImg') },
    ];
    return (
        <div>
            <ScrollToTop />
            <div className="font-eighteen mb-3"><strong>{t('deviceManagement.enrollment.smartPhoneContent1')}</strong></div>
            <ol className="d-flex flex-column gap-3">
                {EnrollSmartPhoneList.map((item, index) => (
                    <li key={index}>
                        <div className="pb-1">{item.enrollPhoneContent}</div>
                        {item.enrollPhoneImagePath &&
                            <Image
                                dataTestId={`imgEnrollPhone_${index}`}
                                path={item.enrollPhoneImagePath}
                                alttext={item.altText}
                                className="img-fluid"
                            />
                        }
                    </li>
                ))}
            </ol>
        </div>
    )
}
export default EnrollSmartPhone;